import {
  GetIsBrokerTltAlternateSignerSenderFragment,
  GetIsBrokerUserFragment,
  GetIsInstitutionUserUserFragment,
  GetIsInstitutionViewerUserFragment,
  GetIsMembershipAgreementSignedUserFragment,
  GetIsUnaccreditedSellerUserFragment,
  GetUserCountryUserFragment,
  GetUserInitialsUserFragment,
  HasInvestorTypeUserFragment,
  HasMultiFactorAuthenticationUserFragment,
  HasUserRoleUserFragment,
  InvestorStatus,
  InvestorType,
  UserMultiFactorAuthenticationStatus,
  UserRole,
} from "@/gql";

export const getUserInitials = (user: GetUserInitialsUserFragment) =>
  `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`.toUpperCase();

export const getIsInstitutionUser = ({
  investorStatus,
  institutionId,
}: GetIsInstitutionUserUserFragment) =>
  investorStatus === InvestorStatus.Institutional && !!institutionId;

export const getIsBroker = ({
  investorType,
}: GetIsBrokerUserFragment | GetIsBrokerTltAlternateSignerSenderFragment) =>
  investorType === InvestorType.Broker;

export const getIsUnaccreditedSeller = ({
  investorType,
}: GetIsUnaccreditedSellerUserFragment) =>
  investorType === InvestorType.UnaccreditedSeller;

export const getIsMembershipAgreementSigned = (
  user: GetIsMembershipAgreementSignedUserFragment,
) =>
  user.institution
    ? user.institution.membershipAgreementSigned
    : user.membershipAgreementSigned;

export const hasUserRole = (user: HasUserRoleUserFragment, role: UserRole) =>
  user.roles.includes(role);

export const hasInvestorType = (
  user: HasInvestorTypeUserFragment,
  type: InvestorType,
) => user.investorType === type;

export const getUserCountry = (user: GetUserCountryUserFragment) => {
  const isInstitutionUser = getIsInstitutionUser(user);
  const country = isInstitutionUser ? user.institution?.country : user.country;

  if (!country)
    throw new Error(`User does not contain a country when it should`);

  return country;
};

export const getIsInstitutionViewer = (
  user: GetIsInstitutionViewerUserFragment,
) => {
  const isInstitutionViewer =
    getIsInstitutionUser(user) && hasUserRole(user, UserRole.Viewer);

  return isInstitutionViewer;
};

export const getIsAppMfaEnabled = (
  user: HasMultiFactorAuthenticationUserFragment,
) =>
  user.multiFactorAuthentications.app ===
  UserMultiFactorAuthenticationStatus.Active;
