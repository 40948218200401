import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Box, HStack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton, Skeleton } from "@/components/common";
import { FormRadioTile, FormTextAreaInput } from "@/components/react-hook-form";
import {
  AccreditationQuestion,
  CurrentContextDocument,
  useAnswerInstitutionAccreditationQuestionsMutation,
} from "@/gql";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";

import { InstitutionAccreditationFormProps } from "./InstitutionAccreditationForm";

const OtherAccreditationCriteriaQuestionKey = `in-institutional-other-v3`;

interface InstitutionAccreditationFormValues {
  readonly accreditationOptionKey?: string | null;
  readonly accreditationOptionText?: string | null;
}

const initialValues = {
  accreditationOptionKey: null,
  accreditationOptionText: null,
};

export const INInstitutionAccreditationForm = ({
  onSuccess,
  questionGroup,
}: InstitutionAccreditationFormProps) => {
  const { t } = useTranslation();
  const mutation = useAnswerInstitutionAccreditationQuestionsMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const accQuestions = questionGroup?.questions || [];
  const validationSchema = Yup.object().shape({
    accreditationOptionKey: Yup.string()
      .oneOf(accQuestions.map((q: AccreditationQuestion) => q.key))
      .required(t`required`),
    accreditationOptionText: Yup.string().when(`accreditationOptionKey`, {
      is: OtherAccreditationCriteriaQuestionKey,
      then: (schema) => schema.required(t`required`),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  });

  const mapVariables = ({
    accreditationOptionKey,
    accreditationOptionText,
  }: InstitutionAccreditationFormValues) => ({
    input: {
      answers: [
        { key: accreditationOptionKey || ``, text: accreditationOptionText },
      ],
    },
  });

  const { handleSubmit, isLoading, control, formState, watch } = useFormQL({
    mutation,
    mode: `onChange`,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });

  const accreditationOptionKey = watch(`accreditationOptionKey`);

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      id="institution-accreditation-form"
    >
      <FormRadioTile.Group
        size="md"
        p={{ base: 4, lg: 6 }}
        borderRadius="md"
        bg="white"
        borderColor="grey.200"
        borderWidth={0.5}
        boxShadow={shadows.card}
        gap={4}
        w={{ base: `full`, lg: 160 }}
      >
        <Text textStyle="heading-lg">{t`in_institution_accreditation_title`}</Text>
        <VStack alignItems="flex-start" gap={4} py={2}>
          {accQuestions.map((q: AccreditationQuestion) => (
            <FormRadioTile.Tile
              key={q.id}
              name="accreditationOptionKey"
              control={control}
              value={q.key}
              alignItems="flex-start"
            >
              <Box justifyContent="center" alignItems="flex-start">
                <Text>{q.text}</Text>
              </Box>
            </FormRadioTile.Tile>
          ))}
        </VStack>
        {accreditationOptionKey === OtherAccreditationCriteriaQuestionKey && (
          <VStack alignItems="flex-start" w="full">
            <Text fontWeight={500}>{t`please_describe`}</Text>
            <FormTextAreaInput
              w="full"
              control={control}
              name="accreditationOptionText"
              label=""
              placeholder={t`description`}
              labelSrOnly
            />
          </VStack>
        )}
      </FormRadioTile.Group>
      <HStack p={2} mt={6} justifyContent="flex-end" w="full">
        <HiiveButton
          sentryLabel="[InstitutionAccreditationPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          form="institution-accreditation-form"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading}
          onClick={handleSubmit}
          isDisabled={!formState?.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </HStack>
    </form>
  );
};

export const INInstitutionAccreditationFormSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} gap={2}>
    <Skeleton h={100} />
    <Skeleton h={75} />
    <Skeleton h={110} />
    <Skeleton h={75} />
    <Skeleton h={75} />
    <Skeleton h={100} />
    <VStack alignItems="flex-end" w="full">
      <Skeleton h={10} w={155} />
    </VStack>
  </VStack>
);
