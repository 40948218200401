import { QueryHookOptions } from "@apollo/client";

import {
  CurrentContextQuery,
  CurrentContextQueryVariables,
  useCurrentContextQuery,
} from "@/gql";

import { useToken } from "./useToken";

const useCurrentContext = (
  baseOptions?: QueryHookOptions<
    CurrentContextQuery,
    CurrentContextQueryVariables
  >,
) => {
  const token = useToken();

  const { data, loading } = useCurrentContextQuery({
    skip: !token,
    ...baseOptions,
  });

  return {
    actor: data?.currentContext?.currentActor,
    userNotificationToken: data?.currentContext?.userNotificationToken,
    loading,
  };
};

export default useCurrentContext;
