const locale = {
  add_entity_type: `Add Entity Type`,
  add_new_entity: `Add New Entity`,
  corporation: `Corporation`,
  create_entity: `Create Entity`,
  entity_type: `Entity Type`,
  entity_created_successfully: `Entity created successfully!`,
  entity_saved_successfully: `Entity saved successfully!`,
  entity_type_is_required: `Entity type is required`,
  entity_type_name_is_required: `Entity type name is required`,
  individual: `Individual`,
  jurisdiction_of_formation: `Jurisdiction of Formation`,
  jurisdiction_of_formation_required: `Jurisdiction of formation is required`,
  legal_entity_name: `Legal Entity Name`,
  legal_entity_name_description: `If you plan to sell from this entity, this should be the name that appears on the share certificate(s). If you plan to purchase, this should  be the name that will appear on the share certificate(s) once a transaction closes.`,
  legal_entity_name_description_buyer: `This is the name that will appear on your share certificates (or other proof of ownership) once the transaction closes.`,
  legal_entity_name_description_seller: `This should be the name that appears on the share certificates (or other proof of ownership).`,
  legal_entity_name_required: `Legal entity name is required`,
  new_entity: `New Entity`,
  new_purchasing_entity: `New Purchasing Entity`,
  new_purchasing_entity_description: `Enter the legal name of the entity that will purchase the shares.`,
  new_selling_entity: `New Selling Entity`,
  new_selling_entity_description: `Enter the name of the legal entity that holds the shares you are selling.`,
  llc: `LLC`,
  limited_partnership: `Limited Partnership`,
  other: `Other`,
  partnership: `Partnership`,
  provide_entity_type: `Provide Entity Type`,
  remove_entity: `Remove Entity`,
  remove_entity_confirmation_message: `Are you sure you want to remove this entity? Once removed, you will no longer be able to use it for any future transactions.`,
  remove_entity_confirmation_header: `Remove {{entity}} as a transacting entity?`,
  remove_entity_confirmation_success_message: `Entity archived successfully`,
  remove_entity_confirmation_title: `Remove Entity`,
  search_country: `Search country`,
  select_entity_type: `Select entity type`,
  transacting_entities: `Transacting Entities`,
  transacting_entities_individual_description: `Manage your transacting entities by adding or removing them as needed. Entities involved in an active transaction can only be removed once the transaction is closed. If you need to update an active entity, please reach out to <a>execution@hiive.com</a> for support.`,
  transacting_entities_institution_description: `Manage your transacting entities by adding or removing them as needed. Entities involved in an active transaction can only be removed once the transaction is closed. If you need to update an active entity, please reach out to <a>execution@hiive.com</a> for support.`,
  trust: `Trust`,
};

export default locale;
