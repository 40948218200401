import { AnimatePresence, motion } from "framer-motion";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { FormTextInput } from "@/components/react-hook-form";
import { HoldingType } from "@/gql";

import { HoldingDetailsFormFieldValues, HoldingField } from "./types";

export const SelectedHoldingsList = ({
  holdings,
  onRemoveCompany,
  control,
}: {
  readonly holdings: (HoldingField & { id: string })[];
  readonly onRemoveCompany: (companyIndex: number) => void;
  readonly control: Control<HoldingDetailsFormFieldValues>;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" w="full" gap={4} mt={holdings.length > 0 ? 7 : 0}>
      <AnimatePresence>
        {holdings.map(({ id, name, type, logoUrl }, index) => (
          <motion.div
            key={id}
            initial={{ translateY: -10, opacity: 0, height: 0, scale: 0.98 }}
            animate={{ translateY: 0, opacity: 1, scale: 1, height: `auto` }}
            transition={{
              type: `spring`,
              stiffness: 700,
              damping: 30,
            }}
            exit={{
              opacity: 0,
              height: 0,
              translateY: -40,
              transition: {
                opacity: { duration: 0.09, ease: `easeOut` },
                height: { duration: 0.2, ease: `easeIn` },
                scale: { duration: 0.2, ease: `easeOut` },
                translateY: { duration: 0.1, ease: `easeOut` },
              },
            }}
          >
            <Flex
              direction="column"
              p={5}
              borderRadius="md"
              borderWidth={0.5}
              borderColor="grey.200"
              boxShadow="card"
              gap={6}
            >
              <Flex align="center" gap={4}>
                {logoUrl && (
                  <Box h={6} w={6}>
                    <Image
                      m="0 auto"
                      h={6}
                      maxW={6}
                      objectFit="contain"
                      src={logoUrl}
                      alt={name}
                      fallback={<Skeleton h={6} w={6} />}
                    />
                  </Box>
                )}

                {type === HoldingType.Listed && (
                  <Text textStyle="heading-md">{name}</Text>
                )}

                {type === HoldingType.Unlisted && (
                  <FormTextInput
                    autoComplete="off"
                    label={t(`company_name`)}
                    name={`holdings.${index}.name`}
                    placeholder={t(`enter_company_name`)}
                    control={control}
                    maxW="24rem"
                  />
                )}

                <Button
                  flex="none"
                  ml="auto"
                  onClick={() => onRemoveCompany(index)}
                  variant="boxed-grey"
                  px={4}
                  py={2}
                  minW={20}
                >
                  {t(`remove`)}
                </Button>
              </Flex>
              <FormTextInput
                type="number"
                autoComplete="off"
                key={id}
                label={t(`how_many_shares_or_options_owned`)}
                name={`holdings.${index}.numShares`}
                placeholder={t(`approximate_number_of_shares`)}
                pattern="[0-9,\.]*(.[0-9,\.]+)?"
                control={control}
                maxW="24rem"
              />
            </Flex>
          </motion.div>
        ))}
      </AnimatePresence>
    </Flex>
  );
};
