import { useTranslation } from "react-i18next";

import { OnboardingContainer } from "@/components/onboarding";
import { RequestEmailVerificationPage as RequestEmailVerificationPageV2 } from "@/components/onboarding-v2";
import { useSuitabilityUpfront } from "@/hooks/featureFlags";

import RequestEmailVerificationPage from "./RequestEmailVerificationPage";

export const RequestUpdatedEmailVerification = () => {
  const { t } = useTranslation();
  const isSUPEnabled = useSuitabilityUpfront();
  if (isSUPEnabled) return <RequestEmailVerificationPageV2 />;

  return (
    <OnboardingContainer heading={t`request_updated_email_verification`}>
      <RequestEmailVerificationPage />
    </OnboardingContainer>
  );
};
