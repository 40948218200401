import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { useModal } from "@/hooks";

const EnrollAppMfaErrorModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`enrollment_error`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          <Text>{t(`enrollment_error_description`)}</Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[EnrollAppMfaError/Submit]"
            submitText={t(`close_mfa_setup`)}
            onClick={closeModal}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default EnrollAppMfaErrorModal;
