import * as Sentry from "@sentry/nextjs";
import { initialize, LDClient } from "launchdarkly-js-client-sdk";
import {
  ReactNode,
  useEffect,
  useRef,
  useState,
  createContext,
  useMemo,
} from "react";

import { useCurrentContextQuery } from "@/gql";
import { FeatureFlags } from "@/hooks/featureFlags";

interface ProviderProps {
  clientSideId?: string;
  children?: ReactNode;
}

const LD_TIMEOUT = 5;

export enum LDStatus {
  Unfetched,
  Success,
  Failure,
}

const defaultFlags: FeatureFlags = {
  "hiive.trace_full_graphql": false,
  "hiive.enable_maintenance": false,
  "hiive.preqin_integration": true,
  "hiive.marketplace.react_hook_form_refactor": false,
  "marketplace.hiive_50": true,
  "marketplace.multiple_holdings": true,
  "marketplace.sell_side_broker_portal": true,
  "issuer.fee_discount.new_proceeds_section": true,
  "issuer.managed_markets.enable": true,
  "issuer.managed_markets.fast_follow": false,
  "execution.seller_external_account": false,
  "execution.transaction_execution_automation_system": false,
  "execution.multi_entity_experience": false,
  "marketplace.suitability_upfront": false,
  "marketplace.suppress_notifications": false,
  "marketplace.trusted-contact-person": true,
  "issuer.auth_system.marketplace_configuration": false,
  "marketplace.suitability_v3": false,
  "marketplace.front_poc": false,
  "issuer.company_approved_buyers.enable": false,
  "issuer.auth_system.marketplace_session": false,
};

export const LDStatusContext = createContext<{
  readonly status: LDStatus;
  readonly client?: LDClient;
  readonly flags: FeatureFlags;
}>({
  status: LDStatus.Unfetched,
  client: undefined,
  flags: defaultFlags,
});

const AsyncLDProvider: React.FC<ProviderProps> = ({
  children,
  clientSideId,
}) => {
  const LDClientRef = useRef<LDClient | undefined>();
  const [flags, setFlags] = useState<FeatureFlags>(defaultFlags);
  const [status, setStatus] = useState<LDStatus>(LDStatus.Unfetched);
  const { data, loading } = useCurrentContextQuery();

  const currentActor = data?.currentContext?.currentActor;

  useEffect(() => {
    if (!loading && clientSideId) {
      const user = currentActor
        ? {
            key: process.env.NEXT_PUBLIC_E2E
              ? `e2e2e2e2-e2e2-e2e2-e2e2-e2e2e2e2e2ea`
              : currentActor.id,
            anonymous: false,
          }
        : { anonymous: true };

      const client = initialize(clientSideId, user, {
        streaming: true,
        bootstrap: defaultFlags,
      });

      client.on(`change`, (items) => {
        const newFlags = Object.keys(items).reduce(
          (acc, key) => ({ ...acc, [key]: items[key].current }),
          {},
        );
        setFlags((currentFlags) => ({ ...currentFlags, ...newFlags }));
        setStatus(LDStatus.Success);
      });
      client.on(`failed`, () => {
        Sentry.captureMessage(`LD Failed to load`);
        setStatus(LDStatus.Failure);
      });

      client.waitForInitialization(LD_TIMEOUT).catch(() => {
        Sentry.captureMessage(
          `LD failed to initialize after ${LD_TIMEOUT} seconds`,
        );
        setStatus(LDStatus.Failure);
      });

      LDClientRef.current = client;
    }

    return () => {
      LDClientRef.current?.close();
    };
  }, [clientSideId, currentActor, loading]);

  const context = useMemo(
    () => ({ status, client: LDClientRef.current, flags }),
    [status, LDClientRef.current, flags],
  );

  if (status === LDStatus.Unfetched) {
    return null;
  }

  return (
    <LDStatusContext.Provider value={context}>
      {children}
    </LDStatusContext.Provider>
  );
};

export default AsyncLDProvider;
