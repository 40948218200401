import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Card, Flex, Link, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";
import { FormRadioTile } from "@/components/react-hook-form";
import {
  InvestorType,
  UserWithInstitutionFragment,
  useTransitionCurrentStepMutation,
  useUpdateOnboardingMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { getIsBroker } from "@/utils";

import { BrokerAgreement } from "./BrokerAgreement";
import { SellerAgreement } from "./SellerAgreement";
import { TraderAgreement } from "./TraderAgreement";

interface ClickwrapAgreementFormValues {
  readonly hasAgreedToClickwrapAgreement?: boolean | null;
}

const initialValues = (user: UserWithInstitutionFragment) => ({
  hasAgreedToClickwrapAgreement:
    user.onboardingV2?.hasAgreedToClickwrapAgreement,
});

const validationSchema = Yup.object().shape({
  hasAgreedToClickwrapAgreement: Yup.bool().nullable().required().isTrue(),
});

const mapVariables = (onboardingId: string) => ({
  hasAgreedToClickwrapAgreement,
}: ClickwrapAgreementFormValues) => ({
  onboardingId,
  input: {
    hasAgreedToClickwrapAgreement,
  },
});

const UserAgreement = ({ actor }: { actor: UserWithInstitutionFragment }) => {
  switch (actor.investorType) {
    case InvestorType.Trader:
      return <TraderAgreement />;
    case InvestorType.UnaccreditedSeller:
      return <SellerAgreement />;
    case InvestorType.Broker:
      return <BrokerAgreement />;
    default:
      throw new Error(`User agreement not found`);
  }
};

const subheaderKeyLookup = {
  [InvestorType.Trader]: `trader_customer_agreement_subheader`,
  [InvestorType.Broker]: `broker_customer_agreement_subheader`,
  [InvestorType.UnaccreditedSeller]: `seller_customer_agreement_subheader`,
};

export const ClickwrapAgreementPage = () => {
  const actor = useCurrentActor();
  const isBroker = getIsBroker(actor);
  const mutation = useUpdateOnboardingMutation();
  const { t } = useTranslation();
  const [
    transitionCurrentStepMutation,
    isTransitioningCurrentStep,
  ] = useMutationWithError(
    useTransitionCurrentStepMutation(),
    `transitionCurrentStep`,
  );

  const onSuccess = () =>
    transitionCurrentStepMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });

  const { handleSubmit, isLoading, control, formState, watch } = useFormQL({
    mutation,
    mapVariables: mapVariables(actor.onboardingV2!.id),
    initialValues: initialValues(actor),
    validationSchema,
    onSuccess,
  });

  const hasSelectedDecline = watch(`hasAgreedToClickwrapAgreement`) === false;

  const subheaderKey =
    subheaderKeyLookup[
      actor.investorType as
        | InvestorType.Trader
        | InvestorType.UnaccreditedSeller
        | InvestorType.Broker
    ];

  return (
    <OnboardingContainerV2 canGoBack metaTitle="Customer Agreement">
      <Flex direction="column">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`customer_agreement`)} *
          </Text>
          <Text color="grey.900">
            <Trans
              i18nKey={subheaderKey}
              components={{
                a: (
                  <Link
                    fontWeight={500}
                    href="/terms-and-conditions"
                    rel="noreferrer"
                    target="_blank"
                    textDecoration="underline"
                  />
                ),
              }}
            />
          </Text>
        </VStack>

        <SlideAnimation>
          <form onSubmit={handleSubmit}>
            <Card maxW="780px" w="full" flex="1" p={7}>
              <UserAgreement actor={actor} />

              <VStack spacing={6} mt={14}>
                {hasSelectedDecline && (
                  <Text color="red.900" fontSize="sm">
                    <Trans
                      i18nKey={
                        isBroker
                          ? `cannot_access_hiive_connect_until_agree_to_ca`
                          : `cannot_access_hiive_until_agree_to_ca`
                      }
                      components={{
                        a: (
                          <Link
                            textDecoration="underline"
                            href="mailto:support@hiive.com"
                            rel="noreferrer"
                            target="_blank"
                          />
                        ),
                      }}
                    />
                  </Text>
                )}

                <FormRadioTile.Group
                  size="sm"
                  gridTemplateColumns={{
                    base: `1fr`,
                    md: `1fr 1fr 1fr`,
                  }}
                  gridColumnGap={4}
                  bg="white"
                  padding={0}
                >
                  <FormRadioTile.Tile
                    name="hasAgreedToClickwrapAgreement"
                    control={control}
                    value={false}
                  >
                    <Text>{t(`decline`)}</Text>
                  </FormRadioTile.Tile>
                  <FormRadioTile.Tile
                    name="hasAgreedToClickwrapAgreement"
                    control={control}
                    value
                  >
                    <Text>{t(`accept`)}</Text>
                  </FormRadioTile.Tile>
                </FormRadioTile.Group>
              </VStack>
            </Card>

            <Flex w="full" justify="flex-end" mt={8}>
              <HiiveButton
                sentryLabel="[ClickwrapAgreementPage/Submit]"
                variant="rounded-solid-salmon"
                type="submit"
                size="xl"
                maxW="unset"
                w={{ base: `full`, sm: `unset` }}
                isLoading={isLoading || isTransitioningCurrentStep}
                isDisabled={!formState.isValid}
              >
                {t(`next`)}
              </HiiveButton>
            </Flex>
          </form>
        </SlideAnimation>
      </Flex>
    </OnboardingContainerV2>
  );
};
