import { useEffect } from "react";

import { VStack, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  useRequestEmailVerificationMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  useCustomToast,
  useMutationWithError,
  useRouteToNextOnboardingStep,
} from "@/hooks";

const useCheckEmailVerified = ({
  actor: { emailVerified },
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  useEffect(() => {
    if (!emailVerified) return;
    routeToNextStep();
  }, []);
};

const REQUEST_EMAIL_VERIFICATION_PAGE_VIEWED_KEY = `REQUEST_EMAIL_VERIFICATION_PAGE_VIEWED_KEY`;

const RequestEmailVerificationPage = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { successToast } = useCustomToast();

  const [requestEmailVerificationMutation, loading] = useMutationWithError(
    useRequestEmailVerificationMutation(),
    `requestEmailVerification`,
  );
  useCheckEmailVerified({ actor });

  useEffect(() => {
    const pageViewed = sessionStorage.getItem(
      REQUEST_EMAIL_VERIFICATION_PAGE_VIEWED_KEY,
    );
    if (pageViewed === `true`) return;

    sessionStorage.setItem(REQUEST_EMAIL_VERIFICATION_PAGE_VIEWED_KEY, `true`);

    requestEmailVerificationMutation();
  }, []);

  const onSuccess = () => {
    successToast(`Verification email sent!`);
  };

  const handleClick = () => {
    requestEmailVerificationMutation().then(onSuccess);
  };

  return (
    <VStack mt={7} spacing={6}>
      <Text align="center" textStyle="deprecated-heading-lg">
        Check your inbox and click the link to verify your email address.
      </Text>
      <Text align="center" textStyle="deprecated-text-xl">
        Didn&apos;t receive email?
      </Text>
      <HiiveButton
        isLoading={loading}
        size="md"
        variant="rounded-solid-salmon"
        onClick={handleClick}
        sentryLabel="[RequestEmailVerificationPage] Resend Verification Email"
      >
        Resend Verification Email
      </HiiveButton>
    </VStack>
  );
};

export default withCurrentActor(RequestEmailVerificationPage);
