import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Card, CardBody, HStack, Text } from "@chakra-ui/react";

import {
  BankAccountRequestStatus,
  useTransactionOnSellerBankAccountCreatedSubscription,
  TransactionPageTransactionByIdDocument,
  UserBankAccountFragment,
  useUpdateTransactionSellerBankAccountIdMutation,
  ModernTreasuryVerifyAccountCardBankAccountFragment,
  BankAccountVerificationStatus,
  TransactingEntityItemEntityFragment,
} from "@/gql";

import ModernTreasuryConnectAccountCard from "./ModernTreasuryConnectAccountCard";
import ModernTreasuryVerifyAccountCard from "./ModernTreasuryVerifyAccountCard";
import ModernTreasuryVeryAccountCardContainer from "./ModernTreasuryVerifyAccountCardContainer";
import SelectBankAccountCard from "./SelectBankAccountCard";

/*
 * Loading state shown when we are waiting on webhooks to update our server.
 */
const PendingServerUpdateCard = () => {
  const { t } = useTranslation();

  return (
    <ModernTreasuryVeryAccountCardContainer>
      <CardBody>
        <Card variant="flat">
          <CardBody as={HStack} justifyContent="center" w="full">
            <Text textStyle="heading-md" color="grey.600">
              {t(`submitting_your_details`)}
            </Text>
          </CardBody>
        </Card>
      </CardBody>
    </ModernTreasuryVeryAccountCardContainer>
  );
};

const ModernTreasuryAccountCard = ({
  transactingBankAccount,
  bankAccountRequestStatus,
  transactingSellerEntity: sellerEntity,
}: {
  readonly transactingBankAccount?: UserBankAccountFragment | null;
  readonly bankAccountRequestStatus: BankAccountRequestStatus;
  readonly transactingSellerEntity: TransactingEntityItemEntityFragment;
}) => {
  const router = useRouter();
  const transactionId = router.query.id as string;

  const apolloClient = useApolloClient();

  const [
    updateTransactionSellerBankAccountId,
  ] = useUpdateTransactionSellerBankAccountIdMutation();

  useTransactionOnSellerBankAccountCreatedSubscription({
    variables: {
      transactionId: transactionId || ``,
    },
    onSubscriptionData: () => {
      apolloClient.refetchQueries({
        include: [TransactionPageTransactionByIdDocument],
      });
    },
  });

  const onUseAccount = async (
    bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment,
  ) => {
    await updateTransactionSellerBankAccountId({
      variables: {
        transactionId,
        sellerBankAccountId: bankAccount.id,
      },
    });

    apolloClient.refetchQueries({
      include: [TransactionPageTransactionByIdDocument],
    });
  };

  const onAddAccount = async () => {
    await router.push(`/transactions/${router.query.id}/connect-account`);
  };

  if (transactingBankAccount) {
    return (
      <ModernTreasuryVerifyAccountCard
        transactionBankAccount={transactingBankAccount}
      />
    );
  }

  const isRequested =
    bankAccountRequestStatus === BankAccountRequestStatus.Requested;

  const filteredEntityBankAccounts =
    (!!sellerEntity &&
      sellerEntity.bankAccounts.filter((account) =>
        [
          BankAccountVerificationStatus.PendingVerification,
          BankAccountVerificationStatus.Verified,
          BankAccountVerificationStatus.Manual,
        ].includes(account.verificationStatus),
      )) ||
    [];

  if (isRequested) {
    return filteredEntityBankAccounts.length === 0 ? (
      <ModernTreasuryConnectAccountCard entity={sellerEntity} />
    ) : (
      <SelectBankAccountCard
        bankAccounts={filteredEntityBankAccounts}
        entity={sellerEntity}
        onAddNewAccount={onAddAccount}
        onUseAccount={onUseAccount}
      />
    );
  }

  return <PendingServerUpdateCard />;
};

export default ModernTreasuryAccountCard;
