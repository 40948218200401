import { Trans, useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";

interface SendNewCodeProps {
  readonly onSendNewCode: () => void;
  readonly sentryLabel: string;
  readonly timeLeft: number;
}

export const SendNewCode = ({
  onSendNewCode,
  sentryLabel,
  timeLeft,
}: SendNewCodeProps) => {
  const { t } = useTranslation();

  return (
    <>
      {timeLeft > 0 && (
        <Text color="grey.400" textStyle="text-sm">
          <Trans i18nKey="send_new_code_in" t={t} values={{ timeLeft }} />
        </Text>
      )}
      {timeLeft === 0 && (
        <HiiveButton
          fontSize="sm"
          sentryLabel={sentryLabel}
          textDecoration="underline"
          variant="text-ghost"
          onClick={onSendNewCode}
        >
          {t(`send_new_code`)}
        </HiiveButton>
      )}
    </>
  );
};
