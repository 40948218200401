import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Flex, Hide, Show, Text } from "@chakra-ui/react";

import { TransactingEntityItemEntityFragment } from "@/gql";
import { useCountryList } from "@/hooks";

type EntitiesOptionProps = {
  option: TransactingEntityItemEntityFragment;
  selected?: boolean;
};

const formattedDate = (date: string) => dayjs(date).format(`MMMM D, YYYY`);

const EntitiesOptionMobile = ({
  countryName,
  option,
}: EntitiesOptionProps & {
  countryName: string;
}) => (
  <Flex justifyContent="center" direction="column" textTransform="capitalize">
    <Text fontSize={12} fontWeight={500} lineHeight={1.5}>
      {option?.legalName}
    </Text>
    <Text fontSize={12} fontWeight={400} lineHeight={1.5}>
      {countryName}
    </Text>
  </Flex>
);

const EntitiesOptionDesktop = ({
  countryName,
  insertedAt,
  option,
  selected,
}: EntitiesOptionProps & {
  countryName: string;
  insertedAt: string;
}) => (
  <Flex alignItems="end" justifyContent="space-between" width="100%">
    <Text textTransform="capitalize">
      <Text as="span" fontWeight={500} marginRight={1}>
        {option?.legalName}
      </Text>
      <Text as="span" fontWeight={400} textAlign="right">
        {countryName}
      </Text>
    </Text>
    {!selected && (
      <Hide below="sm">
        <Text fontWeight={400} color="grey.600">
          {insertedAt}
        </Text>
      </Hide>
    )}
  </Flex>
);

const EntitiesOption = ({ option, selected = false }: EntitiesOptionProps) => {
  const { t } = useTranslation();
  const { getName } = useCountryList();
  const countryName = getName(option?.jurisdictionOfFormation?.name);

  return (
    <Flex alignItems="center" justifyContent="space-between" marginRight="4">
      <Show below="sm" ssr={false}>
        <EntitiesOptionMobile option={option} countryName={countryName} />
      </Show>
      <Show above="sm" ssr={false}>
        <EntitiesOptionDesktop
          option={option}
          countryName={countryName}
          insertedAt={t(`added_date`, {
            date: formattedDate(option?.insertedAt),
          })}
          selected={selected}
        />
      </Show>
    </Flex>
  );
};

export default EntitiesOption;
