import { WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PIN_LENGTH,
} from "@/components/common";
import { useCountDown, useModal } from "@/hooks";
import { useDisableSmsMfa, usePreDisableSmsMfa } from "@/hooks/frontegg";

import { SendNewCode } from "./SendNewCode";

interface ModalBodyWithCodeChallengeProps {
  readonly lastFourDigits: string;
  readonly loading: boolean;
  readonly onOpenDisableAllMfaModal: () => void;
  readonly onPinChange: (value: string) => void;
  readonly onPinComplete: (value: string) => void;
  readonly onSendNewCode: () => void;
  readonly timeLeft: number;
}

const ModalBodyWithCodeChallenge = ({
  lastFourDigits,
  loading,
  onOpenDisableAllMfaModal,
  onPinChange,
  onPinComplete,
  onSendNewCode,
  timeLeft,
}: ModalBodyWithCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Trans
        i18nKey="remove_sms_description_with_code"
        t={t}
        components={{
          a: (
            <Button
              as="span"
              cursor="pointer"
              fontWeight={400}
              textDecoration="underline"
              variant="text-ghost"
              whiteSpace="none"
              onClick={onOpenDisableAllMfaModal}
            />
          ),
        }}
        values={{ lastFourDigits }}
      />
      <CodeInput
        disabled={loading}
        onChange={onPinChange}
        onComplete={onPinComplete}
      />
      <SendNewCode
        onSendNewCode={onSendNewCode}
        sentryLabel="[DisableSmsMfaVerify/SendCode]"
        timeLeft={timeLeft}
      />
    </>
  );
};

interface ModalBodyWithoutCodeChallengeProps {
  readonly lastFourDigits: string;
  readonly onOpenDisableAllMfaModal: () => void;
}

const ModalBodyWithoutCodeChallenge = ({
  lastFourDigits,
  onOpenDisableAllMfaModal,
}: ModalBodyWithoutCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <Trans
      i18nKey="remove_sms_description_without_code"
      t={t}
      components={{
        a: (
          <Button
            as="span"
            cursor="pointer"
            fontWeight={400}
            textDecoration="underline"
            variant="text-ghost"
            whiteSpace="none"
            onClick={onOpenDisableAllMfaModal}
          />
        ),
      }}
      values={{ lastFourDigits }}
    />
  );
};

interface ModalFooterWithCodeChallengeProps {
  readonly loading: boolean;
  readonly onPinComplete: () => void;
  readonly pinValue: string;
}

const ModalFooterWithCodeChallenge = ({
  loading,
  onPinComplete,
  pinValue,
}: ModalFooterWithCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <HiiveSubmitButton
      isDisabled={pinValue.length !== PIN_LENGTH || loading}
      sentryLabel="[DisableSmsMfa/Submit]"
      submitText={t(`remove`)}
      type="submit"
      onClick={onPinComplete}
    />
  );
};

interface ModalFooterWithoutCodeChallengeProps {
  readonly loading: boolean;
  readonly onSubmit: () => void;
}

const ModalFooterWithoutCodeChallenge = ({
  loading,
  onSubmit,
}: ModalFooterWithoutCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <HiiveSubmitButton
      isDisabled={loading}
      sentryLabel="[DisableSmsMfa/Submit]"
      submitText={t(`remove`)}
      type="submit"
      onClick={onSubmit}
    />
  );
};

interface DisableSmsMfaModalProps {
  readonly lastFourDigits: string;
  readonly otcToken?: string;
  readonly skipCodeChallenge: boolean;
}

const DisableSmsMfaModal = ({
  lastFourDigits,
  otcToken: initialOtcToken,
  skipCodeChallenge = false,
}: DisableSmsMfaModalProps) => {
  const { t } = useTranslation();
  const { onOpenModal, modals, currentModal } = useModal();
  const preDisableSmsMfa = usePreDisableSmsMfa();
  const [disableMfa, { error: disableError, loading }] = useDisableSmsMfa();
  const { timeLeft, resetTimer } = useCountDown();

  const [pinValue, setPinValue] = useState<string>(``);
  const [otcToken, setOtcToken] = useState<string | undefined>(initialOtcToken);
  const [error, setError] = useState<Error | null>(null);

  const handleOpenDisableAllMfaModal = () => {
    onOpenModal(modals.disableAllMfa(), currentModal)();
  };

  const handlePinChange = (value: string) => setPinValue(value);

  const handlePinComplete = async (value: string) => {
    if (skipCodeChallenge || !otcToken) {
      return;
    }

    const ok = await disableMfa({ code: value, otcToken });

    if (ok) {
      onOpenModal(modals.disableSmsMfaSuccess())();
    }
  };

  const handleSubmit = async () => {
    const ok = await disableMfa();

    if (ok) {
      onOpenModal(modals.disableSmsMfaSuccess())();
    }
  };

  const handleSendNewCode = async () => {
    const { data, error } = await preDisableSmsMfa();

    if (error || !data) {
      setError(new Error(t(`failed_to_disable_mfa`)));
      return;
    }

    const { otcToken: newOtcToken } = data;

    setOtcToken(newOtcToken);
    resetTimer();
  };

  const showError = !!disableError || !!error;
  const errorMessage = error ? t(`failed_to_disable_mfa`) : t(`incorrect_code`);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`remove_sms`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          {skipCodeChallenge ? (
            <ModalBodyWithoutCodeChallenge
              lastFourDigits={lastFourDigits}
              onOpenDisableAllMfaModal={handleOpenDisableAllMfaModal}
            />
          ) : (
            <ModalBodyWithCodeChallenge
              lastFourDigits={lastFourDigits}
              loading={loading}
              onOpenDisableAllMfaModal={handleOpenDisableAllMfaModal}
              onPinChange={handlePinChange}
              onPinComplete={handlePinComplete}
              onSendNewCode={handleSendNewCode}
              timeLeft={timeLeft}
            />
          )}

          {showError && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{errorMessage}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          {skipCodeChallenge ? (
            <ModalFooterWithoutCodeChallenge
              loading={loading}
              onSubmit={handleSubmit}
            />
          ) : (
            <ModalFooterWithCodeChallenge
              loading={loading}
              onPinComplete={() => handlePinComplete(pinValue)}
              pinValue={pinValue}
            />
          )}
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default DisableSmsMfaModal;
