import { useTranslation } from "react-i18next";

import { FormLabel, Text } from "@chakra-ui/react";

import { CreateEntityModalTypes } from "@/components/entities";
import { TextInput } from "@/components/form";

type LegalNameInputProps = Partial<Pick<HTMLInputElement, "disabled">> & {
  isUnaccredittedSeller?: boolean;
  type?: CreateEntityModalTypes;
};

const LegalNameInput = ({
  disabled,
  isUnaccredittedSeller = false,
  type,
}: LegalNameInputProps) => {
  const { t } = useTranslation(`account`, {
    keyPrefix: `entities`,
  });
  const isBuyer = type === CreateEntityModalTypes.Buyer;
  const isSeller = type === CreateEntityModalTypes.Seller;

  const getLabelText = () => {
    if (isBuyer) return t(`legal_entity_name_description_buyer`);
    if (isSeller || isUnaccredittedSeller)
      return t(`legal_entity_name_description_seller`);

    return t(`legal_entity_name_description`);
  };

  return (
    <TextInput
      isDisabled={disabled}
      name="legalName"
      label={
        <>
          <FormLabel htmlFor="legalName">{t(`legal_entity_name`)}</FormLabel>
          <Text textStyle="text-sm">{getLabelText()}</Text>
        </>
      }
    />
  );
};

export default LegalNameInput;
