import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { BaseOperationState } from "./types";

interface DisbleAllMfaParams {
  readonly email: string;
  readonly recoveryCode: string;
}

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/frontegg/identity/resources/auth/v1/user/mfa/recover`;

const initialState = {
  loading: false,
  error: null,
};

const useDisableAllMfa = () => {
  const { t } = useTranslation();
  const user = useAuthUserOrNull();
  const [state, setState] = useState<BaseOperationState>(initialState);

  const disableAllMfa = useCallback(
    async (body: DisbleAllMfaParams) => {
      setState({ loading: true, error: null });

      try {
        if (!user) {
          throw new Error(t(`invalid_request`));
        }

        const response = await fetch(API_URL, {
          method: `POST`,
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          const data = await response.json();

          if (data.errors.length > 0) {
            const errorMessage = t(`incorrect_code`);
            throw new Error(errorMessage);
          }
        }

        setState({ loading: false, error: null });
        return true;
      } catch (error) {
        Sentry.captureException(error);
        setState({ loading: false, error });
        return false;
      }
    },
    [t, user],
  );

  return [disableAllMfa, state] as const;
};

export default useDisableAllMfa;
