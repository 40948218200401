import * as pluralize from "pluralize";
import { Trans, useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { Badge, Flex, Link, Text } from "@chakra-ui/react";

import {
  FeeBreakdown,
  FocusedShareDetails,
  ListingNumShareAndPriceDetails,
  MailtoLink,
  Skeleton,
} from "@/components/common";
import { withConfig } from "@/components/hoc";
import {
  ListingPageListingInfoCardDiscussionFragment,
  ListingPageListingInfoCardListingFragment,
  ListingState,
  RootConfigFragment,
} from "@/gql";
import {
  constants,
  getHasExpired,
  getListingBidCount,
  getListingHasAcceptedBidPrices,
  getListingHasBids,
  getListingNumOfShares,
  getLongDocumentTitleByTransferMethod,
  getShortDocumentTitleByTransferMethod,
  hoursToDays,
  shareTypeToString,
  toTimestamp,
  transferMethodToLongString,
} from "@/utils";

import {
  AcceptedBidSharePriceDetails,
  checkListingTerminalState,
} from "./ListingInfoCard";
import { ListingPartialBidDetails } from "./ListingPartialBidDetails";
import ListingSellerRoundingDisclaimer from "./ListingSellerRoundingDisclaimer";
import PropertyGrid from "./PropertyGrid";
import StatusTile from "./StatusTile";

const OtherDetailsSection = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection
      p={{ base: 4, md: 6 }}
      pb={5}
      data-testid="listing-notes"
    >
      <Flex gap={3} direction="column">
        <Text textStyle="heading-sm">{t(`listing_notes`)}</Text>
        <Text>{listing.otherDetails}</Text>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const FromHiiveSection = ({ lister = false }: { lister?: boolean }) => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection
      p={{ base: 4, md: 6 }}
      pb={5}
      data-testid="hiive-listing-text"
    >
      <Flex gap={3} direction="column">
        <Text textStyle="heading-sm">{t(`placed_by_hiive`)}</Text>
        <Text>
          {t(
            lister
              ? `broker_lister_listing_placed_by_hiive`
              : `listing_placed_by_hiive_description`,
          )}
        </Text>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const HiiveSPVSection = () => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection
      p={{ base: 4, md: 6 }}
      pb={5}
      data-testid="hiive-spv-listing-text"
    >
      <Flex gap={3} direction="column">
        <Text textStyle="heading-sm">{t(`hiive_organized_spv`)}</Text>
        <Text>{t(`spv_listing_description`)}</Text>
        <Text>
          <Trans
            i18nKey="spv_more_information"
            t={t}
            components={[
              <Link
                key="faq"
                href={constants.spv_faq_url}
                fontWeight="medium"
                target="_blank"
              />,
              <MailtoLink
                key="contact"
                fontWeight="medium"
                email={constants.email_funds}
              />,
            ]}
          />
        </Text>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const BidCountSection = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const bidCount = getListingBidCount(listing);

  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection>
      <Flex gap={4} direction="column" align="start">
        <Text textStyle="heading-sm">{t(`activity`)}</Text>
        <Badge variant="grey" as={Text} gap={4}>
          <Text as="strong" textStyle="heading-lg">
            {bidCount}
          </Text>
          {` `}
          {pluralize(t(`bid`), bidCount)}
        </Badge>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const BrokerSharedPropertyGridItems = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  const formattedShareType = shareTypeToString(listing.shareTypeV2);
  const formattedTransferMethod = transferMethodToLongString(
    listing.transferMethod,
  );

  return (
    <>
      <PropertyGrid.Item>
        <PropertyGrid.ItemHeader>{t(`share_type`)}</PropertyGrid.ItemHeader>
        <PropertyGrid.ItemValue>{formattedShareType}</PropertyGrid.ItemValue>
      </PropertyGrid.Item>
      <PropertyGrid.Item>
        <PropertyGrid.ItemHeader>{t(`structure`)}</PropertyGrid.ItemHeader>
        <PropertyGrid.ItemValue>
          {formattedTransferMethod}
        </PropertyGrid.ItemValue>
      </PropertyGrid.Item>
    </>
  );
};

const BrokerListerPropertyGrid = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  const isListingInTerminalState = checkListingTerminalState(listing.state);

  const actualNumberOfShares = getListingNumOfShares(listing, false);

  const { commissionAmount, flatFeeAmount, feeDiscountAmount, netFees } =
    listing.commission || {};

  return (
    <PropertyGrid.Grid>
      <BrokerSharedPropertyGridItems listing={listing} />
      {!isListingInTerminalState && (
        <PropertyGrid.GridItem colSpan={2}>
          <FeeBreakdown
            numShares={actualNumberOfShares}
            pricePerShare={listing.listingPricePerShare}
            feeDiscountApplications={listing.feeDiscountApplications}
            flatFeeAmount={flatFeeAmount}
            commissionAmount={commissionAmount}
            feeDiscountAmount={feeDiscountAmount}
            netFees={netFees}
            combineHiiveFeesLabel={t(`hiive_co_broker_fee`)}
            combineHiiveFeesTooltip={t(`hiive_co_broker_fee_tooltip`)}
            netProceedsTitle={t(`broker_seller_proceeds`)}
            netProceedsSubtitle={t(`gross_of_your_brokerage_fees`)}
            netProceedsTooltip={t(`broker_seller_proceeds_tooltip`)}
            disclaimerVariant="listingBroker"
          />
        </PropertyGrid.GridItem>
      )}
    </PropertyGrid.Grid>
  );
};

const BrokerListerStatusTileSkeleton = () => <Skeleton h="5rem" w="full" />;

const BrokerListerStatusTile = withConfig(
  ({
    listing,
    config,
  }: {
    readonly listing: ListingPageListingInfoCardListingFragment;
    readonly config: RootConfigFragment;
  }) => {
    const { t } = useTranslation();

    const documentTypeLongText = getLongDocumentTitleByTransferMethod(
      listing.transferMethod,
    );
    const documentTypeShortText = getShortDocumentTitleByTransferMethod(
      listing.transferMethod,
    );

    const hoursToSign = hoursToDays(config.stnLoiHoursValidHours);

    const statusText = match(listing.state)
      .with(ListingState.InReview, () =>
        t(`submitted_to_hiive_status`, {
          date: toTimestamp(listing.insertedAt),
        }),
      )
      .with(ListingState.Open, () => t(`listing_live_status`))
      .with(ListingState.ConditionallySold, () =>
        t(`listing_conditionally_sold_status`, {
          documentTypeLongText,
          documentTypeShortText,
          hoursToSign,
        }),
      )
      .with(P.union(ListingState.Closed, ListingState.Withdrawn), () =>
        t(`listing_closed_status`),
      )
      .otherwise(() => null);

    const hasExpired = getHasExpired(listing.expireAt);
    const formattedExpireAt = !!listing.expireAt
      ? toTimestamp(listing.expireAt)
      : ` - `;

    const formattedPlacedAt = !!listing.placedAt
      ? toTimestamp(listing.placedAt)
      : ` - `;

    const showStatusTile =
      !!statusText || !!listing.placedAt || !!listing.expireAt;

    if (!showStatusTile) return null;

    return (
      <StatusTile.Tile>
        {!!listing.placedAt && (
          <StatusTile.TileRow>
            <StatusTile.TileHeader>Placed</StatusTile.TileHeader>
            <Text>{formattedPlacedAt}</Text>
          </StatusTile.TileRow>
        )}
        {!!listing.expireAt && (
          <StatusTile.TileRow>
            <StatusTile.TileHeader>
              {hasExpired ? `Expired` : `Expires`}
            </StatusTile.TileHeader>
            <Text>{formattedExpireAt}</Text>
          </StatusTile.TileRow>
        )}
        {!!statusText && (
          <StatusTile.TileRow>
            <StatusTile.TileHeader>Status</StatusTile.TileHeader>
            <Text>{statusText}</Text>
          </StatusTile.TileRow>
        )}
      </StatusTile.Tile>
    );
  },
  { fallback: <BrokerListerStatusTileSkeleton /> },
);

export const BrokerListerInfoCard = ({
  listing,
  discussion,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
  readonly discussion?: ListingPageListingInfoCardDiscussionFragment;
}) => {
  const { t } = useTranslation();

  const hasAcceptedBidPrices = getListingHasAcceptedBidPrices(listing);
  const hasBids = getListingHasBids(listing);

  const title = !!discussion
    ? t(`inquiry_for_listing_display_id`, { displayId: listing.displayId })
    : t(`listing_display_id`, { displayId: listing.displayId });

  const isListingV1 = listing.version === 1;

  const showFromHiive = listing.fromHiive && !listing.isHiiveSpv;
  const showSPV = listing.isHiiveSpv;

  return (
    <FocusedShareDetails.Card variant={discussion ? `discussion` : `listing`}>
      <FocusedShareDetails.Header title={title} company={listing.company}>
        <FocusedShareDetails.HeaderCard>
          <ListingNumShareAndPriceDetails listing={listing} />
          {hasAcceptedBidPrices && (
            <AcceptedBidSharePriceDetails listing={listing} />
          )}
        </FocusedShareDetails.HeaderCard>
        <ListingSellerRoundingDisclaimer />
      </FocusedShareDetails.Header>
      <FocusedShareDetails.Content>
        <FocusedShareDetails.ContentSection>
          <BrokerListerStatusTile listing={listing} />
        </FocusedShareDetails.ContentSection>
        <FocusedShareDetails.ContentSection p={{ base: 4, md: 6 }} pb={5}>
          <Flex direction="column" gap={8}>
            <BrokerListerPropertyGrid listing={listing} />
            {isListingV1 && <ListingPartialBidDetails listing={listing} />}
          </Flex>
        </FocusedShareDetails.ContentSection>
        {listing.otherDetails && <OtherDetailsSection listing={listing} />}
        {showFromHiive && <FromHiiveSection lister />}
        {showSPV && <HiiveSPVSection />}
        {hasBids && <BidCountSection listing={listing} />}
      </FocusedShareDetails.Content>
    </FocusedShareDetails.Card>
  );
};
