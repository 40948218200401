import { CardHeader, HStack, Text } from "@chakra-ui/react";

import { ActivityCardBadge } from "@/components/common";
import { CompanyListingCardHeaderListingFragment } from "@/gql";

const CompanyListingCardHeader = ({
  listing,
}: {
  readonly listing: CompanyListingCardHeaderListingFragment;
}) => {
  const { displayId } = listing;

  return (
    <CardHeader>
      <HStack justifyContent="space-between">
        <HStack>
          <ActivityCardBadge variant="listing" title="Listing" />
          <Text textStyle="text-lg" color="grey.900">
            {displayId}
          </Text>
        </HStack>
      </HStack>
    </CardHeader>
  );
};

export default CompanyListingCardHeader;
