import { Flex } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import { WithQuery } from "@/components/common";
import {
  CompanyLastRoundDetailsCard,
  CompanyLastRoundDetailsCardSkeleton,
  CompanyHistoricalPricingCardSkeleton,
  PricingChartWrapper,
} from "@/components/companies";
import { FrontChatProvider } from "@/components/providers";
import {
  CompanyPageInfoCompanyFragment,
  useCompanyPageInfoCompanyByIdQuery,
} from "@/gql";
import { useFrontPOC } from "@/hooks/featureFlags";

import { CompanyDescriptionCard } from "./CompanyDescriptionCard";
import { CompanyDescriptionCardSkeleton } from "./CompanyDescriptionCardSkeleton";

const CompanyInfoContent = ({
  company,
}: {
  readonly company: CompanyPageInfoCompanyFragment;
}) => {
  const isFrontPOCEnabled = useFrontPOC();
  const { priceChartConfig } = company;

  const content = (
    <>
      <Meta title={`Company - ${company.name}`} />
      <Flex direction="column" gap={4}>
        <CompanyDescriptionCard company={company} />
        {priceChartConfig.displayChart && (
          <PricingChartWrapper company={company} />
        )}
        <CompanyLastRoundDetailsCard company={company} />
      </Flex>
    </>
  );

  return isFrontPOCEnabled ? (
    <FrontChatProvider company={company.name}>{content}</FrontChatProvider>
  ) : (
    content
  );
};

export const CompanyInfoSkeleton = () => (
  <Flex direction="column" gap={4}>
    <CompanyDescriptionCardSkeleton />
    <CompanyHistoricalPricingCardSkeleton />
    {/* TODO:: check if needs to add new skeleton for new pricing chart */}
    <CompanyLastRoundDetailsCardSkeleton />
  </Flex>
);

export const CompanyInfo = ({ companyId }: { readonly companyId: string }) => {
  const query = useCompanyPageInfoCompanyByIdQuery({
    variables: { id: companyId },
  });

  return (
    <WithQuery query={query} fallback={<CompanyInfoSkeleton />}>
      {({ data: { companyById: company } }) => (
        <CompanyInfoContent company={company} />
      )}
    </WithQuery>
  );
};
