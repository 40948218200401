/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import find from "lodash/find";
import isNil from "lodash/isNil";
import * as pluralize from "pluralize";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  GridItem,
  ModalBody,
  Show,
  SimpleGrid,
  usePrevious,
} from "@chakra-ui/react";

import {
  FocusedShareDetails,
  FullDivider,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveNextButton,
  ListingNumShareAndPriceDetailsPreview,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  AcceptedBidSharePriceDetails,
  ListingSellerRoundingDisclaimer,
  ListingTransactionSummary,
  ShareSeriesMakeupInput,
} from "@/components/postings";
import {
  ModifyListingModalCompanyFragment,
  ModifyListingModalListingFragment,
} from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import {
  constants,
  currencyValue,
  getAreFeesHighForListing,
  roundUp,
  sumShareSeriesMakeup,
} from "@/utils";

import { ModifyListingLotFields } from "./ModifyListingLotFields";
import { ModifyListingModalStepFormContext } from "./ModifyListingModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { ModifyListingModalFormValues } from "./types";

const getFormValuesFromYupContext = (
  context: Yup.TestContext,
): ModifyListingModalFormValues =>
  find(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // yup doesn't fully type the context object for version < 1.0, we will need to migrate
    context.from,
    `value.shareSeriesMakeup`,
  )?.value;

export const useValidationSchema = (
  listing: ModifyListingModalListingFragment,
  company: ModifyListingModalCompanyFragment,
) => {
  const { t } = useTranslation();

  const minTransactionSizeError = (minSize: string) =>
    t(`min_transaction_size_error`, { minSize: minSize });

  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const minLotValueNumber = isPartiallySold
    ? constants.min_listing_size_for_partial_bids.number
    : constants.min_listing_size.number;

  const minLotValueText = isPartiallySold
    ? constants.min_listing_size_for_partial_bids.text
    : constants.min_listing_size.text;

  const highestCompanyBidPrice = company?.currentPrices?.highestBid
    ? company.currentPrices.highestBid / 100
    : null;

  return Yup.object().shape({
    pricePerShare: Yup.number()
      .nullable()
      .test({
        name: `checkListingMeetsMinValue`,
        params: {},
        message: minTransactionSizeError(minLotValueText),
        test: function (pricePerShare: number) {
          const sharePrice = pricePerShare || highestCompanyBidPrice;

          if (!sharePrice) return true;

          const numShares = sumShareSeriesMakeup(this.parent.shareSeriesMakeup);

          return sharePrice * numShares >= minLotValueNumber;
        },
      }),
    shareSeriesMakeup: Yup.array()
      .max(
        constants.max_share_classes_for_listing,
        `Can specify at most ${
          constants.max_share_classes_for_listing
        } ${pluralize(`class`, constants.max_share_classes_for_listing)}`,
      )
      .of(
        Yup.object().shape({
          shareSeries: Yup.string().nullable().required(`Required`),
          numShares: Yup.number()
            .nullable()
            .required(`Required`)
            .min(1, `Must have at least 1 share`)
            .test({
              name: `checkListingMeetsMinValue`,
              params: {},
              message: minTransactionSizeError(minLotValueText),

              test: function () {
                const {
                  shareSeriesMakeup,
                  pricePerShare,
                } = getFormValuesFromYupContext(this);

                const numShares = sumShareSeriesMakeup(shareSeriesMakeup);

                const sharePrice = pricePerShare || highestCompanyBidPrice;

                if (!sharePrice) return true;

                return sharePrice * numShares >= minLotValueNumber;
              },
            }),
        }),
      ),
  });
};

interface ShareDetailsProps
  extends StepPropsV2<StepKeys, ModifyListingModalFormValues> {
  readonly listing: ModifyListingModalListingFragment;
  readonly company: ModifyListingModalCompanyFragment;
}

const ShareDetails = ({
  values,
  listing,
  company,
  setValues,
  stepRouter,
}: ShareDetailsProps) => {
  const { stepControls } = stepRouter;

  const { closeModal } = useModal();
  const { t } = useTranslation();

  const { shareSeriesMakeup, pricePerShare } = values;

  // This code deals with the special case where we move from having 2 share series to 1
  // We need to update the number of shares under the hood - for the lone series - to make it match the original total
  const previousShareSeriesMakeupLength = usePrevious(shareSeriesMakeup.length);
  const currentShareSeriesMakeupLength = shareSeriesMakeup.length;

  useEffect(() => {
    if (
      previousShareSeriesMakeupLength !== 2 ||
      currentShareSeriesMakeupLength !== 1
    )
      return;

    setValues({
      ...values,
      shareSeriesMakeup: [
        {
          ...shareSeriesMakeup[0],
          numShares: listing.numSharesAvailable,
        },
      ],
    });
  }, [currentShareSeriesMakeupLength]);

  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const isHighFeesWarningVisible =
    !isNil(values.pricePerShare) &&
    getAreFeesHighForListing({
      highestBid: company?.currentPrices?.highestBid,
      pricePerShare: values.pricePerShare,
      numberOfShares: sumShareSeriesMakeup(values.shareSeriesMakeup),
    }) &&
    !isPartiallySold;

  const validationSchema = useValidationSchema(listing, company);

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });
  const numSharesBelowConditionallySoldAmountError = t(
    `num_shares_below_conditionally_sold_amount_error`,
  );

  const onSuccess = () => stepControls.nextStep();

  useStepValidator({
    Context: ModifyListingModalStepFormContext,
    stepKey: stepKeys.modifyListingShareDetails,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  const canModifyShareSeriesMakeup =
    !isPartiallySold || shareSeriesMakeup.length === 1;

  const hasAcceptedBidPrices = listing.acceptedBidPrices.length >= 1;
  const canSeeAcceptedBidPrices = hasAcceptedBidPrices && !listing.solicited;

  const numSharesPreview = roundUp(shareSeriesMakeup[0].numShares);
  const pricePerSharePreview = !isNil(pricePerShare)
    ? currencyValue(pricePerShare)
    : null;

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          <GridItem colSpan={2}>
            <ModifyListingLotFields listing={listing} />
          </GridItem>

          <GridItem colSpan={2}>
            <ListingTransactionSummary
              isHighFeesWarningVisible={isHighFeesWarningVisible}
              pricePerShare={pricePerShare}
              shareSeriesMakeup={shareSeriesMakeup}
              listingId={listing.id}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FocusedShareDetails.Header
              title={t(`listing_preview`)}
              variant="listing-preview"
            >
              <FocusedShareDetails.HeaderCard>
                <ListingNumShareAndPriceDetailsPreview
                  numShares={numSharesPreview}
                  pricePerShare={pricePerSharePreview}
                  listing={listing}
                />
                {canSeeAcceptedBidPrices && (
                  <AcceptedBidSharePriceDetails listing={listing} />
                )}
              </FocusedShareDetails.HeaderCard>
              <ListingSellerRoundingDisclaimer />
            </FocusedShareDetails.Header>
          </GridItem>

          {canModifyShareSeriesMakeup && (
            <>
              <GridItem colSpan={2}>
                <FullDivider />
              </GridItem>
              <GridItem colSpan={2}>
                <ShareSeriesMakeupInput
                  excludedErrorMessages={[
                    minTransactionSizeError,
                    numSharesBelowConditionallySoldAmountError,
                  ]}
                />
              </GridItem>
            </>
          )}
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[ModifyListing/ShareDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveNextButton
          sentryLabel="[ModifyListing/ShareDetails/Submit]"
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};

export default ShareDetails;
