import capitalize from "lodash/capitalize";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Spacer, Text } from "@chakra-ui/react";

import {
  MailtoLink,
  SensitiveText,
  TileRow,
  TileHeader,
} from "@/components/common";
import {
  BidState,
  BidStatusBidFragment,
  BidStatusListingFragment,
  SigningProcedure,
  TransactionState,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  constants,
  getLongDocumentTitleByDocumentType,
  getSecuritySpecialistContact,
  getShortDocumentTitleByDocumentType,
} from "@/utils";

const SecuritySpecialistLink = ({
  listing,
}: {
  readonly listing: BidStatusListingFragment;
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(listing.company);

  return (
    <Text as="span" fontWeight="medium">
      <MailtoLink
        email={securitySpecialistEmail}
        subject={`Contact ${listing.company.name} Security Specialist`}
      >
        {`${listing.company.name} Security Specialist`}
      </MailtoLink>
    </Text>
  );
};

const BidStatus = ({ bid }: { readonly bid: BidStatusBidFragment }) => {
  const actor = useCurrentActor();

  const { t } = useTranslation();

  const longDocumentTitle = bid.transaction?.documentTypeV2
    ? getLongDocumentTitleByDocumentType(bid.transaction.documentTypeV2)
    : null;
  const shortDocumentTitle = bid.transaction?.documentTypeV2
    ? getShortDocumentTitleByDocumentType(bid.transaction?.documentTypeV2)
    : null;

  const { bidStatus, statusDescription } = match(bid)
    .with(
      {
        transaction: {
          state: TransactionState.InReview,
        },
      },
      () => ({
        bidStatus: `In Review: A Security Specialist will contact you soon to clarify a few details and help advance your transaction.`,
        statusDescription: (
          <>
            For any questions or information contact:
            <br />
            <MailtoLink
              email={constants.email_accounts}
              subject="Transaction Review Inquiry"
              fontWeight="medium"
            >
              {constants.email_accounts}
            </MailtoLink>
          </>
        ),
      }),
    )
    .with(
      { transaction: { state: TransactionState.IssuerApprovalDeclined } },
      () => ({
        bidStatus: `Cancelled: Issuer approval declined`,
        statusDescription: null,
      }),
    )
    .with(
      {
        transaction: { state: TransactionState.Cancelled },
      },
      () => ({ bidStatus: `Cancelled`, statusDescription: null }),
    )
    .with(
      {
        listing: { isHiiveSpv: false },
        transaction: {
          state: TransactionState.BidAccepted,
          signingProcedure: SigningProcedure.Manual,
        },
      },
      () => ({
        bidStatus: `${capitalize(bid.state)} - Custom ${longDocumentTitle}`,
        statusDescription: (
          <>
            The next step in this transaction is to sign a {longDocumentTitle}.
            For the present transaction, a Security Specialist will provide a
            customized {shortDocumentTitle}. The transaction may proceed once
            the {shortDocumentTitle} is executed by both parties.
          </>
        ),
      }),
    )
    .with(
      {
        listing: { isHiiveSpv: false },
        transaction: { signingProcedure: SigningProcedure.Manual },
      },
      () => ({
        bidStatus: `${capitalize(bid.state)} - Custom ${longDocumentTitle}`,
        statusDescription: null,
      }),
    )
    .with({ state: BidState.InReview }, () => ({
      bidStatus: `In review: Your bid is being reviewed, and Hiive will notify you once it is placed on the seller’s listing.`,
      statusDescription: null,
    }))
    .with({ state: BidState.Active, brokerId: actor.id }, () => ({
      bidStatus: `Live: The seller can accept or counter your bid.`,
      statusDescription: null,
    }))
    .with(
      {
        state: BidState.Superseded,
      },
      () => ({
        bidStatus: `Superseded - Another bid offer has been made on this listing`,
        statusDescription: null,
      }),
    )
    .with(
      {
        brokerId: actor.id,
        transaction: { rofr: true },
      },
      () => ({
        bidStatus: `ROFR Exercised  - Your bid triggered a right of first refusal which was then exercised. The party exercising its ROFR will therefore be substituted for you as the buyer in this transaction.`,
        statusDescription: (
          <Text>
            Please contact the Hiive Security Specialist representing you{` `}
            <MailtoLink
              email={bid.buyerEmail || constants.email_security_specialist}
              subject={`Broker questions on ${bid.company.name}`}
              fontWeight="medium"
            >
              {bid.buyerEmail || constants.email_security_specialist}
            </MailtoLink>
            {` `}
            to discuss possible next steps.
          </Text>
        ),
      }),
    )
    .with(
      {
        buyerId: actor.id,
        transaction: { rofr: true },
      },
      () => ({
        bidStatus: `ROFR Exercised  - Your bid triggered a right of first refusal which was then exercised. The party exercising its ROFR will therefore be substituted for you as the buyer in this transaction.`,
        statusDescription: (
          <Text>
            Please contact the{` `}
            <SecuritySpecialistLink listing={bid.listing} /> to discuss possible
            next steps.
          </Text>
        ),
      }),
    )
    .with(
      {
        counterpartyId: actor.id,
        transaction: { rofr: true },
      },
      () => ({
        bidStatus: `ROFR Exercised - Your shares are being purchased by a different buyer, who either matched the bid you initially accepted, or exercised a right of first refusal to substitute itself for the original buyer.`,
        statusDescription: null,
      }),
    )
    .with(
      {
        listing: { isHiiveSpv: true },
        state: BidState.Accepted,
      },
      () => ({
        bidStatus: capitalize(bid.state),
        statusDescription: (
          <TileRow>
            <TileHeader />
            <Trans
              i18nKey="spv_transaction_status_description"
              t={t}
              components={[
                <MailtoLink
                  key="spv_email"
                  fontWeight="medium"
                  email={constants.email_funds}
                />,
              ]}
            />
          </TileRow>
        ),
      }),
    )
    .otherwise(() => ({
      bidStatus: capitalize(bid.state),
      statusDescription: null,
    }));

  return (
    <>
      <TileHeader>Status</TileHeader>
      <div data-testid="bid-status">
        <SensitiveText>{bidStatus}</SensitiveText>
      </div>
      {statusDescription && (
        <>
          <Spacer />
          <SensitiveText>{statusDescription}</SensitiveText>
        </>
      )}
    </>
  );
};

export default BidStatus;
