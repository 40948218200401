import { yupResolver } from "@hookform/resolvers/yup";
import { WarningCircle } from "@phosphor-icons/react";
import i18next from "i18next";
import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Link, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  HiiveBackButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { ModalKind } from "@/components/providers";
import { FormTextInput } from "@/components/react-hook-form";
import { useCurrentActor, useModal } from "@/hooks";
import { useDisableAllMfa } from "@/hooks/frontegg";

const getValidationSchema = (t: typeof i18next.t) =>
  Yup.object({
    recoveryCode: Yup.string().required(t(`recovery_code_is_required`)),
  });

const DisableAllMfaModal = () => {
  const { t } = useTranslation();
  const { closeModal, onOpenModal, modals, previousModal } = useModal();
  const actor = useCurrentActor();
  const [disableAllMfa, { loading, error }] = useDisableAllMfa();

  const { email } = actor;

  const validationSchema = getValidationSchema(t);

  type DisableAllMfaFormValues = Yup.InferType<typeof validationSchema>;

  const { control, handleSubmit } = useForm<DisableAllMfaFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      recoveryCode: ``,
    },
  });

  const onSubmit: SubmitHandler<DisableAllMfaFormValues> = useCallback(
    async ({ recoveryCode }) => {
      const ok = await disableAllMfa({ email, recoveryCode });

      if (ok) {
        onOpenModal(modals.disableAllMfaSuccess())();
      }
    },
    [disableAllMfa, email, modals, onOpenModal],
  );

  const handleOnBack = () => {
    if (!previousModal) {
      closeModal();
      return;
    }

    if (previousModal.kind === ModalKind.DisableAppMfa) {
      const { skipCodeChallenge } = previousModal;
      onOpenModal(modals.disableAppMfa(skipCodeChallenge))();
    } else if (previousModal.kind === ModalKind.DisableSmsMfa) {
      const { lastFourDigits, otcToken, skipCodeChallenge } = previousModal;
      onOpenModal(
        modals.disableSmsMfa(skipCodeChallenge, lastFourDigits, otcToken),
      )();
    }
  };

  return (
    <HiiveModalContentWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HiiveModalHeader>
          <Text>{t(`account_recovery`)}</Text>
        </HiiveModalHeader>
        <ModalBody>
          <VStack alignItems="flex-start" gap={4}>
            <Text>{t(`account_recovery_description`)}</Text>
            <FormTextInput
              control={control}
              label={t(`your_recovery_code`)}
              name="recoveryCode"
              type="number"
            />
            {error && (
              <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
                <WarningCircle color="#C4383A" size={14} weight="fill" />
                <Text textStyle="text-sm">{error.message}</Text>
              </CallOut>
            )}
            <Text>
              <Trans
                i18nKey="account_recovery_help_text"
                components={{
                  a: (
                    <Link
                      textDecoration="underline"
                      href="mailto:accounts@hiive.com"
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Text>
          </VStack>
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper>
            <HiiveBackButton
              sentryLabel="[DisableAllMfa/Back]"
              onClick={handleOnBack}
            >
              {t(`back`)}
            </HiiveBackButton>
            <HiiveSubmitButton
              isDisabled={loading}
              sentryLabel="[DisableAllMfa/Submit]"
              submitText={t(`remove_mfa`)}
              type="submit"
            />
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </form>
    </HiiveModalContentWrapper>
  );
};

export default DisableAllMfaModal;
