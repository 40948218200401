import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Stack, Text } from "@chakra-ui/react";

import {
  CreateEntityModalTypes,
  DEFAULT_ENTITY_FORM_VALUES,
} from "@/components/entities";
import { withCurrentActor } from "@/components/hoc";
import {
  AddEntityInput,
  UserWithInstitutionFragment,
  EntityType,
  InvestorType,
} from "@/gql";

import { EntityTypeInput } from "./EntityTypeInput";
import { EntityTypeSelect } from "./EntityTypeSelect";
import { JurisdictionOfFormationInput } from "./JurisdictionOfFormationInput";
import { LegalNameInput } from "./LegalNameInput";

type EntityFormFieldsProps = {
  actor: UserWithInstitutionFragment;
  filterIndividualEntityType?: boolean;
  transactionId?: string;
  type?: CreateEntityModalTypes;
};

const EntityFormFields = withCurrentActor(
  ({
    actor,
    filterIndividualEntityType = false,
    transactionId,
    type,
  }: EntityFormFieldsProps) => {
    const { t } = useTranslation();
    const { setValues, values } = useFormikContext<AddEntityInput>();
    const showEntityTypeInput = values.type === EntityType.Other;
    const isIndividualEntity = values.type === EntityType.Individual;
    const isInstitution = !!actor.institutionId;

    useEffect(() => {
      if (!isInstitution) {
        if (isIndividualEntity) {
          setValues({
            ...DEFAULT_ENTITY_FORM_VALUES,
            jurisdictionOfFormationId: actor.country?.id ?? ``,
            legalName: actor.name,
            type: values.type,
            transactionId,
          });

          return;
        }
        const shouldClearFields = values.legalName === actor.name;

        if (shouldClearFields) {
          setValues({
            ...DEFAULT_ENTITY_FORM_VALUES,
            type: values.type,
            transactionId,
          });
        }
      }
    }, [
      actor.country?.id,
      actor.name,
      isInstitution,
      setValues,
      values.type,
      transactionId,
    ]);

    const isDisabled = !isInstitution && isIndividualEntity;
    const isBuyer = type === CreateEntityModalTypes.Buyer;
    const isAssigningEntity = !!transactionId;

    return (
      <Stack gap="6">
        {isAssigningEntity && (
          <Text textStyle="text-sm">
            {t(
              isBuyer
                ? `entity_type_buyer_description`
                : `entity_type_seller_description`,
              {
                ns: `transactions`,
              },
            )}
          </Text>
        )}
        <EntityTypeSelect
          isInstitution={isInstitution}
          filterIndividualEntityType={
            !isInstitution && filterIndividualEntityType
          }
        />
        {showEntityTypeInput && <EntityTypeInput />}
        <LegalNameInput
          disabled={isDisabled}
          type={type}
          isUnaccredittedSeller={
            actor.investorType === InvestorType.UnaccreditedSeller
          }
        />
        <JurisdictionOfFormationInput disabled={isDisabled} />
      </Stack>
    );
  },
);

export default EntityFormFields;
