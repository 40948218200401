import { WarningCircle } from "@phosphor-icons/react";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveBackButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PIN_LENGTH,
} from "@/components/common";
import { useCountDown, useModal } from "@/hooks";
import { useEnrollSmsMfa, useVerifySmsMfa } from "@/hooks/frontegg";
import { normalizePhoneNumber } from "@/utils/format";

import { SendNewCode } from "./SendNewCode";

const EnrollSmsMfaVerifyModal = ({
  otcToken: initialOtcToken,
  phoneNumber,
}: {
  readonly otcToken: string;
  readonly phoneNumber: string;
}) => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();
  const [enrollMfa] = useEnrollSmsMfa();
  const [verifyMfa, { loading, error: verifyError }] = useVerifySmsMfa();
  const { timeLeft, resetTimer } = useCountDown();

  const [pinValue, setPinValue] = useState<string>(``);
  const [otcToken, setOtcToken] = useState<string>(initialOtcToken);
  const [error, setError] = useState<Error | null>(null);

  const handleComplete = useCallback(
    async (value: string) => {
      const { data } = await verifyMfa({ otcToken, code: value });

      if (data) {
        const { recoveryCode } = data;

        const mfaSmsEnrollCompleteModal = onOpenModal(
          modals.enrollSmsMfaSuccess(recoveryCode),
        );

        mfaSmsEnrollCompleteModal();
      }
    },
    [verifyMfa, onOpenModal],
  );

  const handleSendNewCode = async () => {
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
    const { data, error } = await enrollMfa(normalizedPhoneNumber);
    if (error || !data) {
      setError(new Error(t(`failed_to_enroll_mfa`)));
      return;
    }

    const { otcToken: newOtcToken } = data;

    setOtcToken(newOtcToken);
    resetTimer();
  };

  const showError = !!verifyError || !!error;
  const errorMessage = error ? t(`failed_to_enroll_mfa`) : t(`incorrect_code`);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`verify_phone_number`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          <Text>
            <Trans
              i18nKey="verify_phone_number_description"
              t={t}
              values={{ phoneNumber }}
            />
          </Text>

          <CodeInput
            disabled={loading}
            onChange={(value: string) => setPinValue(value)}
            onComplete={handleComplete}
          />
          {showError && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{errorMessage}</Text>
            </CallOut>
          )}
          <SendNewCode
            onSendNewCode={handleSendNewCode}
            sentryLabel="[EnrollSmsMfaVerify/SendCode]"
            timeLeft={timeLeft}
          />
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveBackButton
            sentryLabel="[EnrollSmsMfaVerify/Back]"
            onClick={() => onOpenModal(modals.enrollSmsMfa())()}
          >
            {t(`cancel`)}
          </HiiveBackButton>
          <HiiveSubmitButton
            isDisabled={pinValue.length !== PIN_LENGTH || loading}
            sentryLabel="[EnrollSmsMfaVerify/Submit]"
            submitText={t(`verify`)}
            type="submit"
            onClick={() => handleComplete(pinValue)}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default EnrollSmsMfaVerifyModal;
