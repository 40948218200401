import { yupResolver } from "@hookform/resolvers/yup";
import i18next from "i18next";
import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormPhoneNumberInput } from "@/components/react-hook-form";
import { useModal } from "@/hooks";
import { useEnrollSmsMfa } from "@/hooks/frontegg";
import { validPhoneNumber } from "@/utils";
import { normalizePhoneNumber } from "@/utils/format";

const getValidationSchema = (t: typeof i18next.t) =>
  Yup.object({
    phoneNumber: Yup.string()
      .required(t(`phone_number_is_required`))
      .test(
        t(`valid_phone_number`),
        t(`please_enter_a_valid_phone_number`),
        validPhoneNumber,
      ),
  });

const EnrollSmsMfaModal = () => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();
  const [enrollMfa] = useEnrollSmsMfa();

  const validationSchema = getValidationSchema(t);

  type EnrollSmsMfaFormValues = Yup.InferType<typeof validationSchema>;

  const { control, handleSubmit, setError } = useForm<EnrollSmsMfaFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneNumber: ``,
    },
  });

  const onSubmit: SubmitHandler<EnrollSmsMfaFormValues> = useCallback(
    async ({ phoneNumber }) => {
      const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
      const { data, error } = await enrollMfa(normalizedPhoneNumber);
      if (error || !data) {
        setError(`phoneNumber`, {
          message: t(`failed_to_enroll_mfa`),
        });
        return;
      }
      const { otcToken } = data;
      onOpenModal(modals.enrollSmsMfaVerify(otcToken, phoneNumber))();
    },
    [],
  );

  return (
    <HiiveModalContentWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HiiveModalHeader>
          <Text>{t(`set_up_sms`)}</Text>
        </HiiveModalHeader>
        <ModalBody>
          <VStack alignItems="center" gap={4}>
            <Text>{t(`set_up_sms_description`)}</Text>
            <FormPhoneNumberInput
              autoFocus
              control={control}
              label={t(`enter_phone_number`)}
              name="phoneNumber"
            />
          </VStack>
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper>
            <HiiveSubmitButton
              sentryLabel="[EnrollSmsMfa/Submit]"
              submitText={t(`next`)}
              type="submit"
            />
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </form>
    </HiiveModalContentWrapper>
  );
};

export default EnrollSmsMfaModal;
