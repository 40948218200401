import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import { BasicUserFragment, useCurrentContextQuery } from "@/gql";
import { createApolloClient } from "@/hooks/useApolloClient";
import { useServerPreview } from "@/hooks/useServerPreview";
import { useToken } from "@/hooks/useToken";

type SessionState = BasicUserFragment | null;

type SessionProviderProps = {
  children: ReactNode;
};

const DEFAULT_SESSION = null;

export const Session = createContext<SessionState>(DEFAULT_SESSION);

export function SessionProvider({ children }: SessionProviderProps) {
  const apiUrl = useServerPreview() ?? ``;
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const token = useToken();

  useEffect(() => {
    if (token) {
      setClient((prevClient) => {
        if (prevClient != null) {
          return prevClient;
        }

        return createApolloClient(apiUrl, token);
      });
    }
  }, [token, apiUrl]);

  const { data } = useCurrentContextQuery({
    skip: !token && !!client,
    client,
  });

  const value = useMemo(() => data?.currentContext?.currentActor || null, [
    data,
  ]);

  return <Session.Provider value={value}>{children}</Session.Provider>;
}
