import { CopySimple } from "@phosphor-icons/react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  ModalBody,
  Text,
  useClipboard,
  VStack,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { useCustomToast } from "@/hooks";
import { constants } from "@/utils";

export enum EnrollAppMfaSuccessAction {
  Redirect,
  Reload,
}

const EnrollAppMfaSuccessModal = ({
  recoveryCode,
  successAction,
}: {
  readonly recoveryCode?: string;
  readonly successAction?: EnrollAppMfaSuccessAction;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { onCopy } = useClipboard(recoveryCode || ``);
  const { successToast } = useCustomToast();

  const handleCopy = () => {
    onCopy();
    successToast(t(`copied_to_clipboard`));
  };

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`setup_complete`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          {recoveryCode ? (
            <Text>
              <Trans
                i18nKey="setup_complete_description_with_recovery_code"
                components={{ bold: <strong /> }}
                values={{ method: constants.MFA_METHODS.APP }}
              />
            </Text>
          ) : (
            <Trans
              i18nKey="setup_complete_description_without_recovery_code"
              values={{ method: constants.MFA_METHODS.APP }}
            />
          )}

          <Text>{t(`please_refresh`)}</Text>

          {recoveryCode && (
            <VStack alignItems="flex-start" width="full">
              <Text>
                <Trans
                  i18nKey="your_recovery_code_bold"
                  components={{ bold: <strong /> }}
                />
              </Text>
              <InputGroup>
                <Input isReadOnly value={recoveryCode} />
                <InputRightElement>
                  <Box
                    alignItems="center"
                    backgroundColor="grey.50"
                    borderRadius="100%"
                    cursor="pointer"
                    display="flex"
                    height="1.625rem"
                    justifyContent="center"
                    width="1.625rem"
                    flexShrink={0}
                    onClick={handleCopy}
                    _hover={{ backgroundColor: `grey.100` }}
                  >
                    <CopySimple size={14} />
                  </Box>
                </InputRightElement>
              </InputGroup>
            </VStack>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[EnrollAppMfaSuccess/Submit]"
            submitText={t(`refresh_page`)}
            onClick={() =>
              match(successAction)
                .with(EnrollAppMfaSuccessAction.Redirect, () =>
                  router.push(`/dashboard`),
                )
                .otherwise(() => router.reload())
            }
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default EnrollAppMfaSuccessModal;
