/* eslint-disable func-names */

/* eslint-disable object-shorthand */
import { Form, FormikProps } from "formik";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  Fade,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { FullContentWrapper, WithQuery } from "@/components/common";
import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  ScrollSection,
  SectionNavBackButton,
  SectionNavButton,
  SectionNavWrapper,
  transferMethodToTransferTypeChoice,
  transferTypeChoiceToTransferMethod,
} from "@/components/listings";
import {
  InvestorType,
  UnaccreditedSellerModifyListingPageListingFragment,
  UserRole,
  UserWithInstitutionFragment,
  useUnaccreditedSellerModifyListingV2Mutation,
  useUnaccreditedSellerModifyListingPageQuery,
} from "@/gql";
import {
  useBasicSectionScrollTracking,
  useCustomToast,
  useScrollToErrorsOnSubmitEffect,
  UseSectionScrollTrackingGetSectionProps,
} from "@/hooks";
import {
  constants,
  currencyValue,
  hasInvestorType,
  hasUserRole,
  Nullable,
  getListingHasConditionallySoldShares,
} from "@/utils";

import { ListingNotesCard } from "./ListingNotesCard";
import { SharePriceCard } from "./SharePriceCard";
import { SummaryAndConfirmationCard } from "./SummaryAndConfirmationCard";
import { TransferTypeCard } from "./TransferTypeCard";
import { UnaccreditedSellerModifyListingPageContentSkeleton } from "./UnaccreditedSellerModifyListingPageSkeleton";
import { ModifyListingFormValues } from "./types";

const useValidationSchema = (
  listing:
    | UnaccreditedSellerModifyListingPageListingFragment
    | null
    | undefined,
) => {
  const { t } = useTranslation();
  if (!listing) {
    return null;
  }

  const minTransactionSizeError = (minSize: string) =>
    t(`min_transaction_size_error`, { minSize });

  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const minLotValueNumber = isPartiallySold
    ? constants.min_listing_size_for_partial_bids.number
    : constants.min_listing_size.number;

  const minLotValueText = isPartiallySold
    ? constants.min_listing_size_for_partial_bids.text
    : constants.min_listing_size.text;

  return Yup.object().shape({
    otherDetails: Yup.string().nullable(),
    numShares: Yup.number()
      .nullable()
      .required(`Required`)
      .test({
        name: `checkListingMeetsMinValue`,
        params: {},
        message: ` `,
        test: function (numShares: number) {
          const { pricePerShare } = this.parent;

          if (!pricePerShare) return true;

          return pricePerShare * numShares >= minLotValueNumber;
        },
      }),
    pricePerShare: Yup.number()
      .nullable()
      .required(`Required`)
      .test({
        name: `checkListingMeetsMinValue`,
        params: {},
        message: minTransactionSizeError(minLotValueText),
        test: function (pricePerShare: number) {
          const { numShares } = this.parent;

          return pricePerShare * numShares >= minLotValueNumber;
        },
      }),
    transferTypeChoice: Yup.string().nullable().required(`Required`),
    shareSeries: Yup.string().nullable().required(`Required`),
    confirmed: Yup.boolean().nullable().oneOf([true], `Required`),
  });
};

const createInitialValues = (
  listing: UnaccreditedSellerModifyListingPageListingFragment,
): Nullable<ModifyListingFormValues> => ({
  numShares: listing.numSharesAvailable,
  otherDetails: listing.otherDetails || ``,
  pricePerShare: !!listing.listingPricePerShare
    ? listing.listingPricePerShare / 100
    : null,
  confirmed: false,
  transferTypeChoice: transferMethodToTransferTypeChoice(
    listing.transferMethod,
  ),
  shareSeries: listing.shareSeriesMakeup[0].shareSeries,
});

const calculateNextNumShares = (
  formNumSharesValue: number,
  listing: UnaccreditedSellerModifyListingPageListingFragment,
) =>
  formNumSharesValue >= listing.numSharesAvailable
    ? listing.numSharesOriginal +
      (formNumSharesValue - listing.numSharesAvailable)
    : listing.numSharesOriginal -
      (listing.numSharesAvailable - formNumSharesValue);

const mapVariables = (
  listing: UnaccreditedSellerModifyListingPageListingFragment,
) => ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  confirmed: _confirmed,
  numShares,
  transferTypeChoice,
  ...values
}: ModifyListingFormValues) => {
  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const nextNumShares = isPartiallySold
    ? calculateNextNumShares(numShares, listing)
    : numShares;

  return {
    input: {
      ...values,
      ...{ numShares: nextNumShares },
      transferMethod: transferTypeChoiceToTransferMethod(transferTypeChoice),
      pricePerShare: currencyValue(values.pricePerShare),
    },
  };
};

const sectionKeys = {
  SharePrice: `SharePrice`,
  TransferType: `TransferType`,
  ListingNotes: `ListingNotes`,
  SummaryAndConfirmation: `SummaryAndConfirmation`,
} as const;

type SectionKeys = keyof typeof sectionKeys;

interface UnaccreditedSellerModifyListingFormContentProps
  extends FormikProps<Nullable<ModifyListingFormValues>> {
  readonly getSectionProps: UseSectionScrollTrackingGetSectionProps<
    SectionKeys,
    HTMLDivElement
  >;
  readonly containerElement: Element | null;
  readonly listing: UnaccreditedSellerModifyListingPageListingFragment;
}

const UnaccreditedSellerModifyListingFormContent = ({
  getSectionProps,
  containerElement,
  isSubmitting,
  listing,
}: UnaccreditedSellerModifyListingFormContentProps) => {
  useScrollToErrorsOnSubmitEffect({
    isSubmitting,
    containerElement,
  });

  const canModifyTransferMethod = !getListingHasConditionallySoldShares(
    listing,
  );

  return (
    <Box __css={{ counterReset: `section` }}>
      <VStack as={Form} spacing={4} alignItems="flex-start">
        <ScrollSection {...getSectionProps(`SharePrice`)}>
          <SharePriceCard listing={listing} />
        </ScrollSection>
        {canModifyTransferMethod && (
          <ScrollSection {...getSectionProps(`TransferType`)}>
            <TransferTypeCard />
          </ScrollSection>
        )}
        <ScrollSection {...getSectionProps(`ListingNotes`)}>
          <ListingNotesCard />
        </ScrollSection>
        <ScrollSection {...getSectionProps(`SummaryAndConfirmation`)}>
          <SummaryAndConfirmationCard listing={listing} />
        </ScrollSection>
      </VStack>
    </Box>
  );
};

interface UnaccreditedSellerModifyListingPageContentProps {
  readonly actor: UserWithInstitutionFragment;
  readonly listing?: UnaccreditedSellerModifyListingPageListingFragment | null;
}

const UnaccreditedSellerModifyListingPageContent = withCurrentActor(
  ({ listing, actor }: UnaccreditedSellerModifyListingPageContentProps) => {
    const router = useRouter();
    const { successToast } = useCustomToast();
    const validationSchema = useValidationSchema(listing);

    if (
      !listing ||
      !hasInvestorType(actor, InvestorType.UnaccreditedSeller) ||
      !hasUserRole(actor, UserRole.Seller)
    ) {
      router.replace(`/page-not-found`);
      return null;
    }

    const mutation = useUnaccreditedSellerModifyListingV2Mutation();

    const containerRef = useRef<HTMLDivElement>(null);

    const canModifyTransferMethod = !getListingHasConditionallySoldShares(
      listing,
    );

    const visibleSections = [
      { key: sectionKeys.SharePrice, name: `Share Price` },
      canModifyTransferMethod && {
        key: sectionKeys.TransferType,
        name: `Transfer Type`,
      },
      { key: sectionKeys.ListingNotes, name: `Listing Notes` },
      {
        key: sectionKeys.SummaryAndConfirmation,
        name: `Summary & Confirmation`,
      },
    ].reduce((soFar, section) => (section ? [...soFar, section] : soFar), []);

    const {
      getSectionProps,
      getNavButtonProps,
    } = useBasicSectionScrollTracking({
      keys: visibleSections.map(({ key }) => key),
      containerElement: containerRef.current,
    });

    const handleSuccess = () => {
      successToast(`Listing modified.`);
      router.push(`/dashboard/active-bids`);
    };

    const initialValues = createInitialValues(listing);

    return (
      <FullContentWrapper px={{ base: 4, lg: 8 }}>
        <SimpleGrid
          columnGap={6}
          rowGap={4}
          maxW="max-width-lg"
          gridTemplateColumns={{ base: `1fr`, lg: `416px 1fr` }}
          w="full"
        >
          <GridItem gridColumn={{ base: 1, lg: 2 }}>
            <Text textStyle="heading-3xl">Modify Your Listing</Text>
          </GridItem>
          <GridItem display={{ base: `none`, lg: `grid` }}>
            <SectionNavWrapper containerElement={containerRef.current}>
              <SectionNavBackButton
                onClick={() => router.push(`/dashboard`)}
                sentryLabel="[UnaccreditedSellerModifyListingPage/Back]"
              />
              <Fade in>
                <Card w="full">
                  <CardBody>
                    <VStack spacing={2}>
                      {visibleSections.map(({ name, key }, index) => (
                        <SectionNavButton key={key} {...getNavButtonProps(key)}>
                          {index + 1}. {name}
                        </SectionNavButton>
                      ))}
                    </VStack>
                  </CardBody>
                </Card>
              </Fade>
            </SectionNavWrapper>
          </GridItem>
          <GridItem ref={containerRef}>
            <FormikQL
              mutation={mutation}
              mutationNames={[`unaccreditedSellerModifyListingV2`]}
              initialValues={initialValues}
              validationSchema={validationSchema}
              mapVariables={mapVariables(listing)}
              onSuccess={handleSuccess}
            >
              {(formikProps) => (
                <UnaccreditedSellerModifyListingFormContent
                  listing={listing}
                  getSectionProps={getSectionProps}
                  containerElement={containerRef.current}
                  {...formikProps}
                />
              )}
            </FormikQL>
          </GridItem>
        </SimpleGrid>
      </FullContentWrapper>
    );
  },
);

const UnaccreditedSellerModifyListingPage = () => {
  const query = useUnaccreditedSellerModifyListingPageQuery();

  return (
    <WithQuery
      query={query}
      fallback={<UnaccreditedSellerModifyListingPageContentSkeleton />}
    >
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing: listing },
        },
      }) => <UnaccreditedSellerModifyListingPageContent listing={listing} />}
    </WithQuery>
  );
};

export default UnaccreditedSellerModifyListingPage;
