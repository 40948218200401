import { ComponentType } from "react";

import { useRouter } from "next/router";

import { OnboardingUserFragment, useOnboardingCurrentActorQuery } from "@/gql";
import { canAccessPlatform, OnboardingRoutes } from "@/hooks";
import { FeatureFlags, useFeatureFlags } from "@/hooks/featureFlags";
import {
  getNextIncompleteOnboardingStep,
  onboardingSteps,
} from "@/hooks/onboarding";
import { useToken } from "@/hooks/useToken";
import { getIsValidRedirectURL } from "@/utils";

const getAuthenticatedRedirectRoute = (
  user: OnboardingUserFragment,
  featureFlags: FeatureFlags,
  redirectURL?: string,
) => {
  if (!canAccessPlatform(user)) {
    const firstStep = onboardingSteps[OnboardingRoutes.InvestorStatus];
    const nextStep = getNextIncompleteOnboardingStep(
      firstStep,
      user,
      featureFlags,
    );
    return !nextStep ? `/dashboard` : nextStep.route;
  }

  if (!!redirectURL && getIsValidRedirectURL(redirectURL)) return redirectURL;
  return `/dashboard`;
};

export function withGuestRoute<T>(WrappedComponent: ComponentType<T>) {
  const GuestComponent = (props: T) => {
    const token = useToken();

    const { data, loading } = useOnboardingCurrentActorQuery({ skip: !token });

    const isAuthenticated = token || (!loading && !!data?.currentActor);

    const featureFlags = useFeatureFlags();

    const router = useRouter();

    if (isAuthenticated) {
      if (!data?.currentActor) return null;

      const route = getAuthenticatedRedirectRoute(
        data.currentActor,
        featureFlags,
        router.query.redirect as string,
      );
      router.push(route);
      return null;
    }
    return <WrappedComponent {...props} key="wrappedGuestRoute" />;
  };

  return GuestComponent;
}
