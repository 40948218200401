import { FetchResult } from "@apollo/client";
import { useState } from "react";

import {
  CreateFileUploadMutation,
  FileUpload,
  FormField,
  GenerateS3UploadPolicyMutation,
  useCreateFileUploadMutation,
  useGenerateS3UploadPolicyMutation,
} from "@/gql";

const post = (url: string, data: FormData): Promise<null | Error> =>
  new Promise((resolve, reject) => {
    fetch(url, { method: `POST`, body: data })
      .then((resp) => {
        if (resp.status === 204) {
          return `success`;
        }
        return resp.text();
      })
      .then((resp) => {
        if (resp === `success`) {
          return resolve(null);
        }
        // parse xml response
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(resp, `application/xml`);
        const message = xmlDoc.querySelector(`Message`);
        const error = new Error(message?.textContent || `File upload failed`);
        return reject(error);
      });
  });

// `file` field must be the last field in the form
// https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
const constructForm = (file: File, formFields: FormField[]) => {
  const form = new FormData();
  formFields.forEach(({ name, value }) => form.append(name, value));
  form.append(`file`, file);
  return form;
};

const ACCEPTED_FILE_TYPES = [
  `application/pdf`,
  `application/doc`,
  `image/jpeg`,
];

interface UseS3SecureFileUploadParams {
  onUploadComplete: (
    fileUpload: Pick<FileUpload, "id" | "filename" | "url">,
  ) => void;
  onError: (err: Error) => void;
}

interface UseS3SecureFileUpload {
  readonly upload: (file: File) => void;
  readonly loading: boolean;
}

const useS3SecureFileUpload = ({
  onUploadComplete,
  onError,
}: UseS3SecureFileUploadParams): UseS3SecureFileUpload => {
  const [loading, setLoading] = useState<boolean>(false);
  const [objectKey, setObjectKey] = useState<string>(``);
  const [generateS3UploadPolicyMutation] = useGenerateS3UploadPolicyMutation();
  const [createFileUploadMutation] = useCreateFileUploadMutation();

  const uploadFileToS3 = (file: File) => (
    resp: FetchResult<GenerateS3UploadPolicyMutation>,
  ) => {
    if (!resp.data) throw new Error(`Failed to generate s3 upload policy`);

    const { objectKey, formFields, url } = resp.data.generateS3UploadPolicy;

    setObjectKey(objectKey);

    const form = constructForm(file, formFields);

    return post(url, form);
  };

  const createFileUpload = (file: File) => () => {
    const input = {
      filename: file.name,
      contentType: file.type,
      s3ObjectRef: objectKey,
    };

    return createFileUploadMutation({ variables: { input } });
  };

  const onSuccess = (resp: FetchResult<CreateFileUploadMutation>) => {
    const fileUpload = resp.data?.createFileUpload.fileUpload;

    if (!fileUpload) throw new Error(`Failed to create file upload`);

    onUploadComplete(fileUpload);
  };

  const upload = (file: File) => {
    if (!ACCEPTED_FILE_TYPES.includes(file.type))
      throw new Error(`Invalid file type`);

    setLoading(true);

    const variables = { filename: file.name, fileType: file.type };

    generateS3UploadPolicyMutation({ variables })
      .then(uploadFileToS3(file))
      .then(createFileUpload(file))
      .then(onSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  };

  return { upload, loading };
};

export default useS3SecureFileUpload;
