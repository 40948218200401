import EntityCard, { EntityCardProps, EntityCardTypes } from "./EntityCard";
import { EntityItem } from "./EntityItem";

type BuyerEntityCardProps = Omit<EntityCardProps, "children" | "type">;

const BuyerEntityCard = (props: BuyerEntityCardProps) => {
  const { transaction } = props;
  const entity = transaction?.buyerEntity;
  const hasEntity = !!entity;

  return (
    <EntityCard type={EntityCardTypes.Buyer} {...props}>
      {hasEntity && (
        <EntityItem
          entity={entity}
          showDate={false}
          showRemoveButton={false}
          showStatus={false}
        />
      )}
    </EntityCard>
  );
};

export default BuyerEntityCard;
