/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
// TODO: remove non null assertions on SUP completion
import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";
import {
  CurrentContextDocument,
  SubmissionStatus,
  useTransitionCurrentStepMutation,
  useUpdateOnboardingMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";

import { PersonaCard } from "./PersonaCard";

const VerifyIdentityPage = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const [
    transitionCurrentStepMutation,
    isTransitioningCurrentStep,
  ] = useMutationWithError(
    useTransitionCurrentStepMutation(),
    `transitionCurrentStep`,
  );

  const [updateOnboardingMutation, isUpdatingOnboarding] = useMutationWithError(
    useUpdateOnboardingMutation({
      refetchQueries: [CurrentContextDocument],
    }),
    `updateOnboarding`,
  );

  const proceedToNextStep = () => {
    transitionCurrentStepMutation({
      variables: { onboardingId: actor.onboardingV2?.id! },
    });
  };

  const onSkip = async () => {
    await updateOnboardingMutation({
      variables: {
        onboardingId: actor.onboardingV2?.id!,
        input: {
          identityVerificationSubmissionStatus: SubmissionStatus.Skipped,
        },
      },
    });

    proceedToNextStep();
  };

  const onComplete = async () => {
    await updateOnboardingMutation({
      variables: {
        onboardingId: actor.onboardingV2?.id!,
        input: {
          identityVerificationSubmissionStatus: SubmissionStatus.Submitted,
        },
      },
    });

    proceedToNextStep();
  };

  return (
    <OnboardingContainerV2 metaTitle={t(`verify_your_identity`)}>
      <Flex direction="column" w="full" maxW="45rem" gap={8}>
        <VStack spacing={2} alignItems="flex-start">
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`verify_your_identity`)}
          </Text>
          <Text>{t(`we_are_required_by_law_to_verify_your_identity`)}</Text>
        </VStack>
        <SlideAnimation>
          <PersonaCard
            onComplete={onComplete}
            isLoading={isTransitioningCurrentStep}
          />
        </SlideAnimation>
        <HiiveButton
          alignSelf={{ base: `center`, md: `flex-end` }}
          variant="text-grey"
          sentryLabel="[VerifyIdentityPage] Skip for now"
          onClick={onSkip}
          isLoading={isTransitioningCurrentStep || isUpdatingOnboarding}
        >
          {t(`skip_for_now`)}
        </HiiveButton>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default VerifyIdentityPage;
