import { flatMap } from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Flex, HStack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton, Skeleton } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import { FormRadioTile } from "@/components/react-hook-form";
import {
  useInvestingGoalsQuery,
  useAnswerInvestingGoalsMutation,
  CurrentContextDocument,
  InvestingGoalQuestion,
  useTransitionCurrentStepMutation,
  InvestingGoalOption,
  InvestingGoalAnswer,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";

const InvestingGoalsSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} alignItems="flex-start" gap={6}>
    <VStack w="full" alignItems="flex-start">
      <VStack mb={2} w="full" alignItems="flex-start">
        <Skeleton h={20} />
      </VStack>
      <Skeleton h={10} />
      <Skeleton h={10} />
      <Skeleton h={10} />
      <Skeleton h={10} />
      <Skeleton h={10} />
    </VStack>
    <VStack w="full" alignItems="flex-start">
      <VStack mb={2} w="full" alignItems="flex-start">
        <Skeleton h={20} />
      </VStack>
      <Skeleton h={10} />
      <Skeleton h={10} />
      <Skeleton h={10} />
      <Skeleton h={10} />
      <Skeleton h={10} />
    </VStack>
    <HStack justifyContent="flex-end" w="full">
      <Skeleton h={10} w={155} />
    </HStack>
  </VStack>
);

export const InvestingGoalsForm = () => {
  const actor = useCurrentActor();
  const { t } = useTranslation();

  const mutation = useAnswerInvestingGoalsMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const { data, loading } = useInvestingGoalsQuery();
  const [
    transitionCurrentStepMutation,
    isTransitioningCurrentStep,
  ] = useMutationWithError(
    useTransitionCurrentStepMutation(),
    `transitionCurrentStep`,
  );

  const investingGoalsQuestions =
    data?.investingGoalQuestionGroup?.questions || [];

  const previousAnswers = flatMap(
    actor?.investingGoals?.map(({ option }: InvestingGoalAnswer) => option),
  );

  const initialValues = useMemo(
    () =>
      investingGoalsQuestions.reduce(
        (acc, question: InvestingGoalQuestion) => ({
          ...acc,
          [`${question.id}`]:
            previousAnswers.find((answer) => answer.questionId === question?.id)
              ?.id || ``,
        }),
        {},
      ) || {},
    [previousAnswers],
  );

  const validationSchema = Yup.object().shape(
    Object.keys(initialValues).reduce(
      (acc, questionId) => ({
        ...acc,
        [`${questionId}`]: Yup.string().optional(),
      }),
      {},
    ),
  );

  const mapVariables = (investingGoalsAnswers: { [k: string]: string }) => ({
    input: {
      optionIds: Object.values(investingGoalsAnswers),
    },
  });

  const onSuccess = async () => {
    await transitionCurrentStepMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });
  };

  const { handleSubmit, isLoading, control, formState, reset } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });

  useEffect(() => {
    reset(initialValues, { keepDirtyValues: true });
  }, [initialValues]);

  if (loading) return <InvestingGoalsSkeleton />;

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <SlideAnimation>
        <FormRadioTile.Group
          size="md"
          bg="white"
          p={{ base: 4, lg: 6 }}
          borderRadius="md"
          borderColor="grey.200"
          borderWidth={0.5}
          boxShadow={shadows.card}
          gap={6}
          w={{ base: `full`, lg: 160 }}
        >
          <VStack gap={4} w="full" p={2}>
            {investingGoalsQuestions.map((question: InvestingGoalQuestion) => (
              <VStack
                key={question.id}
                alignItems="flex-start"
                w="full"
                py={2}
                gap={4}
              >
                <Text textStyle="heading-lg" mb={2}>
                  {question.text}
                </Text>
                {(question?.options || []).map(
                  (option: InvestingGoalOption) => (
                    <FormRadioTile.Tile
                      key={option.id}
                      // @ts-expect-error due to dynamic form
                      name={question.id!}
                      control={control}
                      value={option.id}
                      alignItems="center"
                    >
                      <Text>{option.text}</Text>
                    </FormRadioTile.Tile>
                  ),
                )}
              </VStack>
            ))}
          </VStack>
        </FormRadioTile.Group>
      </SlideAnimation>
      <Flex w="full" justify="flex-end" mt={8} gap={7}>
        <HiiveButton
          sentryLabel="[InvestorInvestingGoalsPageV2/Submit]"
          variant="text-grey"
          type="button"
          size="xl"
          maxW="unset"
          onClick={onSuccess}
          w={{ base: `full`, lg: `unset` }}
        >
          {t(`skip`)}
        </HiiveButton>
        <HiiveButton
          sentryLabel="[InvestorInvestingGoalsPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading || isTransitioningCurrentStep}
          isDisabled={!formState.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </Flex>
    </form>
  );
};
