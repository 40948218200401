import { parseCookies } from "nookies";
import { ReactNode, createContext } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/state";

export const Token = createContext<string | null>(null);

export function TokenProvider({ children }: { children: ReactNode }) {
  const hiiveSession = useSelector((state: RootState) => state.auth);

  const { token: hiiveToken } = hiiveSession;

  const cookies = parseCookies();
  const { fronteggToken } = cookies;

  const token = fronteggToken || hiiveToken;

  return <Token.Provider value={token}>{children}</Token.Provider>;
}
