/* eslint-disable func-names */

/* eslint-disable object-shorthand */
import { Form, FormikProps } from "formik";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  Fade,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { FullContentWrapper, WithQuery } from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  ScrollSection,
  SectionNavBackButton,
  SectionNavButton,
  SectionNavWrapper,
  transferMethodToTransferTypeChoice,
  transferTypeChoiceToTransferMethod,
} from "@/components/listings";
import {
  useUnaccreditedSellerModifyListingByIdPageQuery,
  UnaccreditedSellerModifyListingByIdPageListingFragment,
  useModifyListingMutation,
} from "@/gql";
import {
  useBasicSectionScrollTracking,
  useCustomToast,
  useScrollToErrorsOnSubmitEffect,
  UseSectionScrollTrackingGetSectionProps,
} from "@/hooks";
import {
  constants,
  currencyValue,
  Nullable,
  getListingHasConditionallySoldShares,
  getTransferMethodByUnaccreditedSellerTransferMethodInput,
} from "@/utils";

import { ListingNotesCard } from "./ListingNotesCard";
import { SharePriceCardV2 } from "./SharePriceCardV2";
import { SummaryAndConfirmationCard } from "./SummaryAndConfirmationCard";
import { TransferTypeCard } from "./TransferTypeCard";
import { UnaccreditedSellerModifyListingPageContentSkeleton } from "./UnaccreditedSellerModifyListingPageSkeleton";
import { ModifyListingFormValues } from "./types";

const useValidationSchema = (
  listing:
    | UnaccreditedSellerModifyListingByIdPageListingFragment
    | null
    | undefined,
) => {
  const { t } = useTranslation();

  if (!listing) {
    return null;
  }

  const minTransactionSizeError = (minSize: string) =>
    t(`min_transaction_size_error`, { minSize });

  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const minLotValueNumber = isPartiallySold
    ? constants.min_listing_size_for_partial_bids.number
    : constants.min_listing_size.number;

  const minLotValueText = isPartiallySold
    ? constants.min_listing_size_for_partial_bids.text
    : constants.min_listing_size.text;

  return Yup.object().shape({
    otherDetails: Yup.string().nullable(),
    numShares: Yup.number()
      .nullable()
      .required(`Required`)
      .test({
        name: `checkListingMeetsMinValue`,
        params: {},
        message: minTransactionSizeError(minLotValueText),
        test: function (numShares: number) {
          const { pricePerShare } = this.parent;

          if (!pricePerShare) return true;

          return pricePerShare * numShares >= minLotValueNumber;
        },
      }),
    pricePerShare: Yup.number()
      .nullable()
      .required(`Required`)
      .test({
        name: `checkListingMeetsMinValue`,
        params: {},
        message: minTransactionSizeError(minLotValueText),
        test: function (pricePerShare: number) {
          const { numShares } = this.parent;

          return pricePerShare * numShares >= minLotValueNumber;
        },
      }),
    transferTypeChoice: Yup.string().nullable().required(`Required`),
    shareSeries: Yup.string().nullable().required(`Required`),
    confirmed: Yup.boolean().nullable().oneOf([true], `Required`),
  });
};

const createInitialValues = (
  listing: UnaccreditedSellerModifyListingByIdPageListingFragment,
): Nullable<ModifyListingFormValues> => ({
  numShares: listing.numSharesAvailable,
  otherDetails: listing.otherDetails || ``,
  pricePerShare: !!listing.listingPricePerShare
    ? listing.listingPricePerShare / 100
    : null,
  confirmed: false,
  transferTypeChoice: transferMethodToTransferTypeChoice(
    listing.transferMethod,
  ),
  shareSeries: listing.shareSeriesMakeup[0].shareSeries,
});

const calculateNextNumShares = (
  formNumSharesValue: number,
  listing: UnaccreditedSellerModifyListingByIdPageListingFragment,
) =>
  formNumSharesValue >= listing.numSharesAvailable
    ? listing.numSharesOriginal +
      (formNumSharesValue - listing.numSharesAvailable)
    : listing.numSharesOriginal -
      (listing.numSharesAvailable - formNumSharesValue);

const mapVariables = (
  {
    confirmed: _confirmed,
    shareSeries,
    numShares,
    transferTypeChoice,
    ...values
  }: ModifyListingFormValues,
  listing: UnaccreditedSellerModifyListingByIdPageListingFragment,
) => {
  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const nextNumShares = isPartiallySold
    ? calculateNextNumShares(numShares, listing)
    : numShares;

  return {
    listingId: listing.id,
    input: {
      ...values,
      ...{
        shareSeriesMakeup: [
          {
            shareSeries: shareSeries,
            numShares: nextNumShares,
          },
        ],
      },
      transferMethod: getTransferMethodByUnaccreditedSellerTransferMethodInput(
        transferTypeChoiceToTransferMethod(transferTypeChoice),
      ),
      pricePerShare: currencyValue(values.pricePerShare),
    },
  };
};

const sectionKeys = {
  SharePrice: `SharePrice`,
  TransferType: `TransferType`,
  ListingNotes: `ListingNotes`,
  SummaryAndConfirmation: `SummaryAndConfirmation`,
} as const;

type SectionKeys = keyof typeof sectionKeys;

interface UnaccreditedSellerModifyListingFormContentProps
  extends FormikProps<Nullable<ModifyListingFormValues>> {
  readonly getSectionProps: UseSectionScrollTrackingGetSectionProps<
    SectionKeys,
    HTMLDivElement
  >;
  readonly containerElement: Element | null;
  readonly listing: UnaccreditedSellerModifyListingByIdPageListingFragment;
}

const UnaccreditedSellerModifyListingFormContent = ({
  getSectionProps,
  containerElement,
  isSubmitting,
  listing,
}: UnaccreditedSellerModifyListingFormContentProps) => {
  useScrollToErrorsOnSubmitEffect({
    isSubmitting,
    containerElement,
  });

  const canModifyTransferMethod = !getListingHasConditionallySoldShares(
    listing,
  );

  return (
    <Box __css={{ counterReset: `section` }}>
      <VStack as={Form} spacing={4} alignItems="flex-start">
        <ScrollSection {...getSectionProps(`SharePrice`)}>
          <SharePriceCardV2 listing={listing} company={listing.company} />
        </ScrollSection>
        {canModifyTransferMethod && (
          <ScrollSection {...getSectionProps(`TransferType`)}>
            <TransferTypeCard />
          </ScrollSection>
        )}
        <ScrollSection {...getSectionProps(`ListingNotes`)}>
          <ListingNotesCard />
        </ScrollSection>
        <ScrollSection {...getSectionProps(`SummaryAndConfirmation`)}>
          <SummaryAndConfirmationCard listing={listing} />
        </ScrollSection>
      </VStack>
    </Box>
  );
};

interface UnaccreditedSellerModifyListingPageContentProps {
  readonly listing: UnaccreditedSellerModifyListingByIdPageListingFragment;
}

const UnaccreditedSellerModifyListingPageContent = ({
  listing,
}: UnaccreditedSellerModifyListingPageContentProps) => {
  const router = useRouter();
  const { successToast } = useCustomToast();
  const { t } = useTranslation();
  const validationSchema = useValidationSchema(listing);

  const mutation = useModifyListingMutation();

  const containerRef = useRef<HTMLDivElement>(null);

  const canModifyTransferMethod = !getListingHasConditionallySoldShares(
    listing,
  );

  const visibleSections = [
    { key: sectionKeys.SharePrice, name: t(`share_price`) },
    canModifyTransferMethod && {
      key: sectionKeys.TransferType,
      name: t(`transfer_type`),
    },
    { key: sectionKeys.ListingNotes, name: t(`listing_notes`) },
    {
      key: sectionKeys.SummaryAndConfirmation,
      name: t(`summary_and_confirmation`),
    },
  ].reduce((soFar, section) => (section ? [...soFar, section] : soFar), []);

  const { getSectionProps, getNavButtonProps } = useBasicSectionScrollTracking({
    keys: visibleSections.map(({ key }) => key),
    containerElement: containerRef.current,
  });

  const handleSuccess = () => {
    successToast(t(`listing_modified`));
    router.push(`/dashboard/${listing.company.id}/active-bids`);
  };

  const initialValues = createInitialValues(listing);

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <SimpleGrid
        columnGap={6}
        rowGap={4}
        maxW="max-width-lg"
        gridTemplateColumns={{ base: `1fr`, lg: `416px 1fr` }}
        w="full"
      >
        <GridItem gridColumn={{ base: 1, lg: 2 }}>
          <Text textStyle="heading-3xl">{t(`modify_your_listing`)}</Text>
        </GridItem>
        <GridItem display={{ base: `none`, lg: `grid` }}>
          <SectionNavWrapper containerElement={containerRef.current}>
            <SectionNavBackButton
              onClick={() => router.push(`/dashboard/${listing.company.id}`)}
              sentryLabel="[UnaccreditedSellerModifyListingPage/Back]"
            />
            <Fade in>
              <Card w="full">
                <CardBody>
                  <VStack spacing={2}>
                    {visibleSections.map(({ name, key }, index) => (
                      <SectionNavButton key={key} {...getNavButtonProps(key)}>
                        {index + 1}. {name}
                      </SectionNavButton>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
            </Fade>
          </SectionNavWrapper>
        </GridItem>
        <GridItem ref={containerRef}>
          <FormikQL
            mutation={mutation}
            mutationNames={[`modifyListing`]}
            initialValues={initialValues}
            validationSchema={validationSchema}
            mapVariables={(variables) =>
              mapVariables(variables as ModifyListingFormValues, listing)
            }
            onSuccess={handleSuccess}
          >
            {(formikProps) => (
              <UnaccreditedSellerModifyListingFormContent
                listing={listing}
                getSectionProps={getSectionProps}
                containerElement={containerRef.current}
                {...formikProps}
              />
            )}
          </FormikQL>
        </GridItem>
      </SimpleGrid>
    </FullContentWrapper>
  );
};

const UnaccreditedSellerModifyListingPageV2 = ({ id }: { id: string }) => {
  const query = useUnaccreditedSellerModifyListingByIdPageQuery({
    variables: { id },
  });

  return (
    <WithQuery
      query={query}
      fallback={<UnaccreditedSellerModifyListingPageContentSkeleton />}
    >
      {({ data: { listingById: listing } }) => {
        if (!listing) return null;

        return <UnaccreditedSellerModifyListingPageContent listing={listing} />;
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerModifyListingPageV2;
