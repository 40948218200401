import { useFormikContext } from "formik";
import isNil from "lodash/isNil";
import { forwardRef, useDeferredValue } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { FormField, MoneyInput } from "@/components/form";
import {
  AskPriceComparisonChart,
  AskPriceComparisonChartDisclaimer,
  BidCancellationWarning,
  FeesDisclaimer,
  getHasAskPriceChart,
  HighFeesWarning,
  ListingFeeBreakdown,
} from "@/components/listings";
import {
  BidState,
  UnaccreditedSellerModifyListingPageBidFragment,
  UnaccreditedSellerModifyListingPageListingFragment,
  UnaccreditedSellerModifyListingPageSharePriceCardCompanyFragment,
  useUnaccreditedSellerModifyListingPageSharePriceCardQuery,
} from "@/gql";
import { useDebouncedCallback } from "@/hooks";
import { getAreFeesHigh, Nullable } from "@/utils";

import { SharePriceCardSkeleton } from "./SharePriceCardSkeleton";
import { ModifyListingFormValues } from "./types";

const getNumberOfBidsToCancel = (
  bids: readonly UnaccreditedSellerModifyListingPageBidFragment[],
  maxShares: number,
) =>
  bids.filter(({ state, numSharesActual, counterNumShares }) => {
    if (state === BidState.Active) {
      return numSharesActual > maxShares;
    }

    if (state === BidState.Countered && !!counterNumShares) {
      return counterNumShares > maxShares;
    }

    return false;
  }).length;

const LotFields = ({
  listing,
}: {
  readonly listing: UnaccreditedSellerModifyListingPageListingFragment;
}) => {
  const {
    values: { numShares, pricePerShare },
    errors,
    touched,
    setFieldTouched,
  } = useFormikContext<Nullable<ModifyListingFormValues>>();
  const hasTransactionSizeError = !isNil(errors.numShares);

  const numberOfBidsToCancel = !isNil(numShares)
    ? getNumberOfBidsToCancel(listing.bids, numShares)
    : 0;

  const showBidCancellationWarning =
    numberOfBidsToCancel > 0 && !(touched.numShares && hasTransactionSizeError);

  const areFeesHigh = getAreFeesHigh({ pricePerShare, numShares });

  const onChangeLotFields = useDebouncedCallback(() => {
    if (!touched.numShares && areFeesHigh) {
      setFieldTouched(`numShares`, true);
      setFieldTouched(`pricePerShare`, true);
    }
  }, 500);

  return (
    <VStack spacing={7} alignItems="flex-start">
      <VStack w="full" spacing={3}>
        <FormField.Control name="numShares">
          <FormField.NumberInput
            label="Number of shares"
            onChange={onChangeLotFields}
          />
          <FormField.Error />
        </FormField.Control>
        {showBidCancellationWarning && (
          <BidCancellationWarning numberOfBidsToCancel={numberOfBidsToCancel} />
        )}
      </VStack>
      <MoneyInput
        label="At what price per share? (USD)"
        name="pricePerShare"
        onChange={onChangeLotFields}
      />
    </VStack>
  );
};

const SharePriceCardContent = forwardRef<
  HTMLDivElement,
  {
    readonly listing: UnaccreditedSellerModifyListingPageListingFragment;
    readonly company: UnaccreditedSellerModifyListingPageSharePriceCardCompanyFragment;
  }
>(({ listing, company }, ref) => {
  const {
    values: { pricePerShare, numShares },
    touched,
  } = useFormikContext<Nullable<ModifyListingFormValues>>();

  const showHighFeesWarning =
    !!touched.numShares && getAreFeesHigh({ pricePerShare, numShares });

  const deferredPricePerShare = useDeferredValue(pricePerShare);

  const askPriceInDollars = !isNil(deferredPricePerShare)
    ? deferredPricePerShare
    : 0;

  const hasAskPriceChart = getHasAskPriceChart(company);

  return (
    <Card w="full" ref={ref}>
      <CardHeader>
        <Text
          textStyle="heading-sm"
          _before={{
            content: `counter(section) ". "`,
          }}
        >
          Share Price
        </Text>
      </CardHeader>
      <CardBody>
        <SimpleGrid columns={12} columnGap={6} rowGap={4}>
          <GridItem colSpan={12}>
            <Text textStyle="heading-2xs">Share Details</Text>
          </GridItem>
          <GridItem colSpan={{ base: 12, xl: 5 }}>
            <LotFields listing={listing} />
          </GridItem>
          <GridItem
            colStart={{ base: 0, xl: 6, "2xl": 7 }}
            colSpan={{ base: 12, xl: 7, "2xl": 6 }}
          >
            <VStack w="full" spacing={4}>
              <ListingFeeBreakdown
                pricePerShare={pricePerShare}
                numShares={numShares}
                listingId={listing.id}
              />
              {showHighFeesWarning && <HighFeesWarning />}
            </VStack>
          </GridItem>
          <GridItem colSpan={12}>
            <FeesDisclaimer />
          </GridItem>
        </SimpleGrid>
      </CardBody>
      {hasAskPriceChart && (
        <CardBody>
          <VStack alignItems="flex-start" spacing={4}>
            <Text textStyle="heading-2xs">How Your ask price Compares</Text>
            <AskPriceComparisonChart
              company={company}
              askPriceInDollars={askPriceInDollars}
            />
            <AskPriceComparisonChartDisclaimer />
          </VStack>
        </CardBody>
      )}
    </Card>
  );
});

export const SharePriceCard = forwardRef<
  HTMLDivElement,
  { readonly listing: UnaccreditedSellerModifyListingPageListingFragment }
>(({ listing }, ref) => {
  const query = useUnaccreditedSellerModifyListingPageSharePriceCardQuery();

  return (
    <WithQuery query={query} fallback={<SharePriceCardSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myCompany: company },
        },
      }) => (
        <SharePriceCardContent ref={ref} company={company} listing={listing} />
      )}
    </WithQuery>
  );
});
