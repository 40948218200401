import { AnimatePresence, motion } from "framer-motion";
import { omit } from "lodash";
import { useState, createContext, ReactNode, useRef, useMemo } from "react";
import { match } from "ts-pattern";

import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";

import { AnimatedModalStep } from "@/components/form";
import {
  WithdrawListingModal,
  WithdrawStandingBidModal,
  CounterBidModal,
  ModifyBidModal,
  ModifyCounterBidModal,
  InviteAlternateSignerModal,
  WithdrawBidModal,
  CreateListingSequenceModal,
  BrokerSubmitListingSequenceModal,
  AcceptStandingBidSequenceModal,
  ModifyListingModal,
  ModifyStandingBidModal,
  PlaceBidSuccessModal,
  PlaceBidSequenceModal,
  PlaceStandingBidSequenceModal,
  AcceptBidSequenceModal,
  AcceptCounterBidSequenceModal,
  EditInstitutionUserModal,
  InviteInstitutionUserModal,
  WithdrawCounterBidModal,
  UnaccreditedSellerWithdrawListingModal,
  UnaccreditedSellerWithdrawListingModalV2,
  UnaccreditedSellerCreateListingSuccessModal,
  UnaccreditedSellerAcceptStandingBidSequenceModal,
  RevokeAlternateSignerInvitationModal,
  PlaceStandingBidSequenceModalFormValues,
  TransactionModificationModal,
  BrokerSubmitBidSequenceModal,
  ChangeEmailModal,
  ChangePhoneModal,
  ChangePasswordModal,
  DisableAppMfaModal,
  DisableAppMfaSuccessModal,
  EnrollAppMfaModal,
  EnrollAppMfaSuccessModal,
  EnrollAppMfaErrorModal,
  DisableSmsMfaModal,
  DisableSmsMfaSuccessModal,
  DisableMfaErrorModal,
  EnrollSmsMfaModal,
  EnrollSmsMfaVerifyModal,
  EnrollSmsMfaSuccessModal,
  DisableAllMfaModal,
  DisableAllMfaSuccessModal,
  CalendlyModal,
  EnrollAppMfaSuccessAction,
} from "@/components/modals";
import {
  AcceptBidModalBidFragment,
  AcceptStandingBidModalStandingBidFragment,
  CounterBidModalBidFragment,
  CreateListingModalCompanyFragment,
  InviteAlternateSignerModalBidFragment,
  ModifyBidModalBidFragment,
  ModifyBidModalCompanyFragment,
  ModifyBidModalListingFragment,
  ModifyListingModalListingFragment,
  ModifyStandingBidModalStandingBidFragment,
  PlaceStandingBidModalCompanyFragment,
  WithdrawListingModalListingFragment,
  WithdrawStandingBidModalStandingBidFragment,
  WithdrawBidModalBidFragment,
  PlaceBidSuccessModalBidFragment,
  EditInstitutionUserModalUserFragment,
  WithdrawCounterBidModalBidFragment,
  ModifyCounterBidModalBidFragment,
  UnaccreditedSellerWithdrawListingModalListingFragment,
  UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
  BrokerSubmitBidSequenceModalCompanyFragment,
  BrokerSubmitBidSequenceModalListingFragment,
  RevokeAlternateSignerInvitationModalDocumentFragment,
  PlaceBidSequenceModalCompanyFragment,
  PlaceBidSequenceModalListingFragment,
  BrokerSubmitListingSequenceModalCompanyFragment,
  CreateListingSuccessModalListingFragment,
  AcceptCounterBidModalBidFragment,
} from "@/gql";
import { useDebounce, useFlowLauncher, useModal } from "@/hooks";

export enum ModalKind {
  CreateListing,
  BrokerSubmitListing,
  ModifyListing,
  WithdrawListing,
  PlaceStandingBid,
  WithdrawStandingBid,
  ModifyStandingBid,
  AcceptStandingBid,
  PlaceBidSuccess,
  PlaceBid,
  ModifyBid,
  WithdrawBid,
  WithdrawCounterBid,
  AcceptBid,
  AcceptCounterBid,
  CounterBid,
  ModifyCounterBid,
  Introduction,
  InviteAlternateSigner,
  RevokeAlternateSignerInvitation,
  DeactivateUser,
  ReviewTransaction,
  EditInstitutionUser,
  InviteInstitutionUser,
  UnaccreditedSellerWithdrawListing,
  UnaccreditedSellerWithdrawListingV2,
  UnaccreditedSellerCreateListingSuccess,
  UnaccreditedSellerAcceptStandingBid,
  SetSecuritySpecialistUser,
  AddBrokerRepresentingHiiveUser,
  RejectBidOrder,
  MarkListingSolicited,
  MarkListingUnsolicited,
  BrokerSubmitBid,
  ChangeTransferType,
  TransactionModification,
  ChangeEmail,
  ChangePhone,
  ChangePassword,
  DisableAppMfa,
  DisableAppMfaSuccess,
  EnrollAppMfa,
  EnrollAppMfaSuccess,
  EnrollAppMfaError,
  DisableSmsMfa,
  DisableSmsMfaSuccess,
  DisableMfaError,
  EnrollSmsMfa,
  EnrollSmsMfaVerify,
  EnrollSmsMfaSuccess,
  DisableAllMfa,
  DisableAllMfaSuccess,
  Calendly,
}

interface EditInstitutionUserModal {
  readonly kind: ModalKind.EditInstitutionUser;
  readonly institutionUser: EditInstitutionUserModalUserFragment;
  readonly hasOtherInstitutionAdmins: boolean;
}

interface InviteInstitutionUserModal {
  readonly kind: ModalKind.InviteInstitutionUser;
}

interface CreateListingModal {
  readonly kind: ModalKind.CreateListing;
  readonly company?: CreateListingModalCompanyFragment;
}

interface BrokerSubmitListingSequenceModal {
  readonly kind: ModalKind.BrokerSubmitListing;
  readonly company?: BrokerSubmitListingSequenceModalCompanyFragment;
}

interface ModifyListingModal {
  readonly kind: ModalKind.ModifyListing;
  readonly listing: ModifyListingModalListingFragment;
}

interface WithdrawListingModal {
  readonly kind: ModalKind.WithdrawListing;
  readonly listing: WithdrawListingModalListingFragment;
}

interface PlaceStandingBidModal {
  readonly kind: ModalKind.PlaceStandingBid;
  readonly company?: PlaceStandingBidModalCompanyFragment;
  readonly initialValues?: Partial<PlaceStandingBidSequenceModalFormValues>;
}

interface WithdrawStandingBidModal {
  readonly kind: ModalKind.WithdrawStandingBid;
  readonly standingBid: WithdrawStandingBidModalStandingBidFragment;
}

interface ModifyStandingBidModal {
  readonly kind: ModalKind.ModifyStandingBid;
  readonly standingBid: ModifyStandingBidModalStandingBidFragment;
}

interface AcceptStandingBidModal {
  readonly kind: ModalKind.AcceptStandingBid;
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

interface PlaceBidModal {
  readonly kind: ModalKind.PlaceBid;
  readonly initialListing?: PlaceBidSequenceModalListingFragment;
  readonly company: PlaceBidSequenceModalCompanyFragment;
}

interface ModifyBidModal {
  readonly kind: ModalKind.ModifyBid;
  readonly listing: ModifyBidModalListingFragment;
  readonly company: ModifyBidModalCompanyFragment;
  readonly bid: ModifyBidModalBidFragment;
}

interface WithdrawBidModal {
  readonly kind: ModalKind.WithdrawBid;
  readonly bid: WithdrawBidModalBidFragment;
}

interface WithdrawCounterBidModal {
  readonly kind: ModalKind.WithdrawCounterBid;
  readonly bid: WithdrawCounterBidModalBidFragment;
}

interface PlaceBidSuccessModal {
  readonly kind: ModalKind.PlaceBidSuccess;
  readonly bid: PlaceBidSuccessModalBidFragment;
}

interface AcceptBidModal {
  readonly kind: ModalKind.AcceptBid;
  readonly bid: AcceptBidModalBidFragment;
}

interface CounterBidModal {
  readonly kind: ModalKind.CounterBid;
  readonly bid: CounterBidModalBidFragment;
}

interface ModifyCounterBidModal {
  readonly kind: ModalKind.ModifyCounterBid;
  readonly bid: ModifyCounterBidModalBidFragment;
}

interface AcceptCounterBidModal {
  readonly kind: ModalKind.AcceptCounterBid;
  readonly bid: AcceptCounterBidModalBidFragment;
}

interface InviteAlternateSignerModal {
  readonly kind: ModalKind.InviteAlternateSigner;
  readonly bid: InviteAlternateSignerModalBidFragment;
}

interface RevokeAlternateSignerInvitationModal {
  readonly kind: ModalKind.RevokeAlternateSignerInvitation;
  readonly document: RevokeAlternateSignerInvitationModalDocumentFragment;
}

interface UnaccreditedSellerWithdrawListingModal {
  readonly kind: ModalKind.UnaccreditedSellerWithdrawListing;
  readonly listing: UnaccreditedSellerWithdrawListingModalListingFragment;
}

interface UnaccreditedSellerWithdrawListingModalV2 {
  readonly kind: ModalKind.UnaccreditedSellerWithdrawListingV2;
  readonly listing: UnaccreditedSellerWithdrawListingModalListingFragment;
}

interface UnaccreditedSellerCreateListingSuccessModal {
  readonly kind: ModalKind.UnaccreditedSellerCreateListingSuccess;
  readonly listing: CreateListingSuccessModalListingFragment;
}

interface UnaccreditedSellerAcceptStandingBidModal {
  readonly kind: ModalKind.UnaccreditedSellerAcceptStandingBid;
  readonly standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment;
}

interface BrokerSubmitBidModal {
  readonly kind: ModalKind.BrokerSubmitBid;
  readonly company: BrokerSubmitBidSequenceModalCompanyFragment;
  readonly initialListing?: BrokerSubmitBidSequenceModalListingFragment;
}

interface TransactionModificationModal {
  readonly kind: ModalKind.TransactionModification;
  readonly name: string;
  readonly title: string;
  readonly description: string | React.ReactElement;
  readonly proposedUpdate: string;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
}

interface ChangeEmailModal {
  readonly kind: ModalKind.ChangeEmail;
}

interface ChangePhoneModal {
  readonly kind: ModalKind.ChangePhone;
}

interface ChangePasswordModal {
  readonly kind: ModalKind.ChangePassword;
}

interface DisableAppMfaModal {
  readonly kind: ModalKind.DisableAppMfa;
  readonly skipCodeChallenge: boolean;
}

interface DisableAppMfaSuccessModal {
  readonly kind: ModalKind.DisableAppMfaSuccess;
}

interface EnrollAppMfaModal {
  readonly kind: ModalKind.EnrollAppMfa;
  readonly qrCode: string;
  readonly successAction: EnrollAppMfaSuccessAction;
}

interface EnrollAppMfaSuccessModal {
  readonly kind: ModalKind.EnrollAppMfaSuccess;
  readonly recoveryCode?: string;
}

interface EnrollAppMfaErrorModal {
  readonly kind: ModalKind.EnrollAppMfaError;
}

interface DisableSmsMfaModal {
  readonly kind: ModalKind.DisableSmsMfa;
  readonly lastFourDigits: string;
  readonly otcToken?: string;
  readonly skipCodeChallenge: boolean;
}

interface DisableSmsMfaSuccessModal {
  readonly kind: ModalKind.DisableSmsMfaSuccess;
}

interface DisableMfaErrorModal {
  readonly kind: ModalKind.DisableMfaError;
}

interface EnrollSmsMfaModal {
  readonly kind: ModalKind.EnrollSmsMfa;
}

interface EnrollSmsMfaVerifyModal {
  readonly kind: ModalKind.EnrollSmsMfaVerify;
  readonly otcToken: string;
  readonly phoneNumber: string;
}

interface EnrollSmsMfaSuccessModal {
  readonly kind: ModalKind.EnrollSmsMfaSuccess;
  readonly recoveryCode?: string;
}

interface DisableAllMfaModal {
  readonly kind: ModalKind.DisableAllMfa;
}

interface DisableAllMfaSuccessModal {
  readonly kind: ModalKind.DisableAllMfaSuccess;
}

interface CalendlyModal {
  readonly kind: ModalKind.Calendly;
  readonly url: string;
}

type Modal =
  | CreateListingModal
  | BrokerSubmitListingSequenceModal
  | ModifyListingModal
  | WithdrawListingModal
  | PlaceStandingBidModal
  | WithdrawStandingBidModal
  | PlaceBidSuccessModal
  | ModifyStandingBidModal
  | AcceptStandingBidModal
  | PlaceBidModal
  | ModifyBidModal
  | WithdrawBidModal
  | AcceptBidModal
  | AcceptCounterBidModal
  | CounterBidModal
  | WithdrawCounterBidModal
  | ModifyCounterBidModal
  | InviteAlternateSignerModal
  | RevokeAlternateSignerInvitationModal
  | EditInstitutionUserModal
  | InviteInstitutionUserModal
  | UnaccreditedSellerWithdrawListingModal
  | UnaccreditedSellerWithdrawListingModalV2
  | UnaccreditedSellerCreateListingSuccessModal
  | UnaccreditedSellerAcceptStandingBidModal
  | BrokerSubmitBidModal
  | TransactionModificationModal
  | ChangeEmailModal
  | ChangePhoneModal
  | ChangePasswordModal
  | DisableAppMfaModal
  | DisableAppMfaSuccessModal
  | EnrollAppMfaModal
  | EnrollAppMfaSuccessModal
  | EnrollAppMfaErrorModal
  | DisableSmsMfaModal
  | DisableSmsMfaSuccessModal
  | DisableMfaErrorModal
  | EnrollSmsMfaModal
  | EnrollSmsMfaVerifyModal
  | EnrollSmsMfaSuccessModal
  | DisableAllMfaModal
  | DisableAllMfaSuccessModal
  | CalendlyModal;

const editInstitutionUser: (
  institutionUser: EditInstitutionUserModalUserFragment,
  hasOtherInstitutionAdmins: boolean,
) => EditInstitutionUserModal = (
  institutionUser,
  hasOtherInstitutionAdmins,
) => ({
  kind: ModalKind.EditInstitutionUser,
  institutionUser,
  hasOtherInstitutionAdmins,
});

const inviteInstitutionUser: () => InviteInstitutionUserModal = () => ({
  kind: ModalKind.InviteInstitutionUser,
});

const createListing: () => CreateListingModal = () => ({
  kind: ModalKind.CreateListing,
});

const brokerSubmitListing: () => BrokerSubmitListingSequenceModal = () => ({
  kind: ModalKind.BrokerSubmitListing,
});

const brokerSubmitListingOnCompany: (
  company: BrokerSubmitListingSequenceModalCompanyFragment,
) => BrokerSubmitListingSequenceModal = (company) => ({
  kind: ModalKind.BrokerSubmitListing,
  company,
});

const createListingOnCompany: (
  company: CreateListingModalCompanyFragment,
) => CreateListingModal = (company) => ({
  kind: ModalKind.CreateListing,
  company,
});

const modifyListing: (
  listing: ModifyListingModalListingFragment,
) => ModifyListingModal = (listing) => ({
  kind: ModalKind.ModifyListing,
  listing,
});

const withdrawListing: (
  listing: WithdrawListingModalListingFragment,
) => WithdrawListingModal = (listing) => ({
  kind: ModalKind.WithdrawListing,
  listing,
});

const placeStandingBid: (
  company?: PlaceStandingBidModalCompanyFragment,
  initialValues?: Partial<PlaceStandingBidSequenceModalFormValues>,
) => PlaceStandingBidModal = (company, initialValues) => ({
  kind: ModalKind.PlaceStandingBid,
  company,
  initialValues,
});

const withdrawStandingBid: (
  standingBid: WithdrawStandingBidModalStandingBidFragment,
) => WithdrawStandingBidModal = (standingBid) => ({
  kind: ModalKind.WithdrawStandingBid,
  standingBid,
});

const modifyStandingBid: (
  standingBid: ModifyStandingBidModalStandingBidFragment,
) => ModifyStandingBidModal = (standingBid) => ({
  kind: ModalKind.ModifyStandingBid,
  standingBid,
});

const acceptStandingBid: (
  standingBid: AcceptStandingBidModalStandingBidFragment,
) => AcceptStandingBidModal = (standingBid) => ({
  kind: ModalKind.AcceptStandingBid,
  standingBid,
});

const placeBid: (
  company: PlaceBidSequenceModalCompanyFragment,
) => PlaceBidModal = (company) => ({
  kind: ModalKind.PlaceBid,
  company,
});

const placeBidOnListing: (
  company: PlaceBidSequenceModalCompanyFragment,
  initialListing: PlaceBidSequenceModalListingFragment,
) => PlaceBidModal = (company, initialListing) => ({
  kind: ModalKind.PlaceBid,
  initialListing,
  company,
});

const modifyBid: (
  company: ModifyBidModalCompanyFragment,
  listing: ModifyBidModalListingFragment,
  bid: ModifyBidModalBidFragment,
) => ModifyBidModal = (company, listing, bid) => ({
  kind: ModalKind.ModifyBid,
  listing,
  company,
  bid,
});

const withdrawBid: (bid: WithdrawBidModalBidFragment) => WithdrawBidModal = (
  bid,
) => ({
  kind: ModalKind.WithdrawBid,
  bid,
});

const placeBidSuccess: (
  bid: PlaceBidSuccessModalBidFragment,
) => PlaceBidSuccessModal = (bid) => ({
  kind: ModalKind.PlaceBidSuccess,
  bid,
});

const acceptBid: (bid: AcceptBidModalBidFragment) => AcceptBidModal = (
  bid,
) => ({
  kind: ModalKind.AcceptBid,
  bid,
});

const acceptCounterBid: (
  bid: AcceptCounterBidModalBidFragment,
) => AcceptCounterBidModal = (bid) => ({
  kind: ModalKind.AcceptCounterBid,
  bid,
});

const counterBid: (bid: CounterBidModalBidFragment) => CounterBidModal = (
  bid,
) => ({
  kind: ModalKind.CounterBid,
  bid,
});

const withdrawCounterBid: (
  bid: WithdrawCounterBidModalBidFragment,
) => WithdrawCounterBidModal = (bid) => ({
  kind: ModalKind.WithdrawCounterBid,
  bid,
});

const modifyCounterBid: (
  bid: ModifyCounterBidModalBidFragment,
) => ModifyCounterBidModal = (bid) => ({
  kind: ModalKind.ModifyCounterBid,
  bid,
});

const inviteAlternateSigner: (
  bid: InviteAlternateSignerModalBidFragment,
) => InviteAlternateSignerModal = (bid) => ({
  kind: ModalKind.InviteAlternateSigner,
  bid,
});

const revokeAlternateSignerInvitation: (
  document: RevokeAlternateSignerInvitationModalDocumentFragment,
) => RevokeAlternateSignerInvitationModal = (document) => ({
  kind: ModalKind.RevokeAlternateSignerInvitation,
  document,
});

const unaccreditedSellerWithdrawListing: (
  listing: UnaccreditedSellerWithdrawListingModalListingFragment,
) => UnaccreditedSellerWithdrawListingModal = (listing) => ({
  kind: ModalKind.UnaccreditedSellerWithdrawListing,
  listing,
});

const unaccreditedSellerWithdrawListingV2: (
  listing: UnaccreditedSellerWithdrawListingModalListingFragment,
) => UnaccreditedSellerWithdrawListingModalV2 = (listing) => ({
  kind: ModalKind.UnaccreditedSellerWithdrawListingV2,
  listing,
});

const unaccreditedSellerCreateListingSuccess: (
  listing: CreateListingSuccessModalListingFragment,
) => UnaccreditedSellerCreateListingSuccessModal = (listing) => ({
  kind: ModalKind.UnaccreditedSellerCreateListingSuccess,
  listing,
});

const unaccreditedSellerAcceptStandingBid: (
  standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
) => UnaccreditedSellerAcceptStandingBidModal = (standingBid) => ({
  kind: ModalKind.UnaccreditedSellerAcceptStandingBid,
  standingBid,
});

const brokerSubmitBid: (
  company: BrokerSubmitBidSequenceModalCompanyFragment,
) => BrokerSubmitBidModal = (company) => ({
  kind: ModalKind.BrokerSubmitBid,
  company,
});

const transactionModification: ({
  name,
  title,
  description,
  proposedUpdate,
  onCancel,
  onConfirm,
}: {
  readonly name: string;
  readonly title: string;
  readonly description: string | React.ReactElement;
  readonly proposedUpdate: string;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
}) => TransactionModificationModal = ({
  name,
  title,
  description,
  proposedUpdate,
  onCancel,
  onConfirm,
}) => ({
  kind: ModalKind.TransactionModification,
  name,
  title,
  description,
  proposedUpdate,
  onCancel,
  onConfirm,
});

const changeEmail: () => ChangeEmailModal = () => ({
  kind: ModalKind.ChangeEmail,
});

const changePhone: () => ChangePhoneModal = () => ({
  kind: ModalKind.ChangePhone,
});

const changePassword: () => ChangePasswordModal = () => ({
  kind: ModalKind.ChangePassword,
});

const disableAppMfa: (skipCodeChallenge: boolean) => DisableAppMfaModal = (
  skipCodeChallenge,
) => ({
  kind: ModalKind.DisableAppMfa,
  skipCodeChallenge,
});

const disableAppMfaSuccess: () => DisableAppMfaSuccessModal = () => ({
  kind: ModalKind.DisableAppMfaSuccess,
});

const enrollAppMfa: (
  qrCode: string,
  successAction: EnrollAppMfaSuccessAction,
) => EnrollAppMfaModal = (qrCode, successAction) => ({
  kind: ModalKind.EnrollAppMfa,
  qrCode,
  successAction,
});

const enrollAppMfaSuccess: (
  recoveryCode?: string,
  successAction?: EnrollAppMfaSuccessAction,
) => EnrollAppMfaSuccessModal = (recoveryCode, successAction) => ({
  kind: ModalKind.EnrollAppMfaSuccess,
  recoveryCode,
  successAction,
});

const enrollAppMfaError: () => EnrollAppMfaErrorModal = () => ({
  kind: ModalKind.EnrollAppMfaError,
});

const disableSmsMfa: (
  skipCodeChallenge: boolean,
  lastFourDigits: string,
  otcToken?: string,
) => DisableSmsMfaModal = (skipCodeChallenge, lastFourDigits, otcToken) => ({
  kind: ModalKind.DisableSmsMfa,
  skipCodeChallenge,
  lastFourDigits,
  otcToken,
});

const disableSmsMfaSuccess: () => DisableSmsMfaSuccessModal = () => ({
  kind: ModalKind.DisableSmsMfaSuccess,
});

const disableMfaError: () => DisableMfaErrorModal = () => ({
  kind: ModalKind.DisableMfaError,
});

const enrollSmsMfa: () => EnrollSmsMfaModal = () => ({
  kind: ModalKind.EnrollSmsMfa,
});

const enrollSmsMfaVerify: (
  phoneNumber: string,
  otcToken: string,
) => EnrollSmsMfaVerifyModal = (otcToken, phoneNumber) => ({
  kind: ModalKind.EnrollSmsMfaVerify,
  otcToken,
  phoneNumber,
});

const enrollSmsMfaSuccess: (
  recoveryCode?: string,
) => EnrollSmsMfaSuccessModal = (recoveryCode) => ({
  kind: ModalKind.EnrollSmsMfaSuccess,
  recoveryCode,
});

const disableAllMfa: () => DisableAllMfaModal = () => ({
  kind: ModalKind.DisableAllMfa,
});

const disableAllMfaSuccess: () => DisableAllMfaSuccessModal = () => ({
  kind: ModalKind.DisableAllMfaSuccess,
});

const calendly: (url: string) => CalendlyModal = (url) => ({
  kind: ModalKind.Calendly,
  url,
});

export const modals = {
  createListing,
  brokerSubmitListing,
  brokerSubmitListingOnCompany,
  createListingOnCompany,
  modifyListing,
  withdrawListing,
  placeStandingBid,
  withdrawStandingBid,
  modifyStandingBid,
  acceptStandingBid,
  placeBid,
  placeBidOnListing,
  modifyBid,
  withdrawBid,
  acceptBid,
  counterBid,
  withdrawCounterBid,
  modifyCounterBid,
  acceptCounterBid,
  inviteAlternateSigner,
  revokeAlternateSignerInvitation,
  placeBidSuccess,
  editInstitutionUser,
  inviteInstitutionUser,
  unaccreditedSellerWithdrawListing,
  unaccreditedSellerWithdrawListingV2,
  unaccreditedSellerCreateListingSuccess,
  unaccreditedSellerAcceptStandingBid,
  brokerSubmitBid,
  transactionModification,
  changeEmail,
  changePhone,
  changePassword,
  disableAppMfa,
  disableAppMfaSuccess,
  enrollAppMfa,
  enrollAppMfaSuccess,
  enrollAppMfaError,
  disableSmsMfa,
  disableSmsMfaSuccess,
  disableMfaError,
  enrollSmsMfa,
  enrollSmsMfaVerify,
  enrollSmsMfaSuccess,
  disableAllMfa,
  disableAllMfaSuccess,
  calendly,
};

interface ModalContextProps {
  readonly currentModal: Modal | null;
  readonly previousModal: Modal | null;
  readonly closeModal: () => void;
  readonly onOpenModal: (
    modal: Modal,
    previousModal?: Modal | null,
  ) => () => void;
  readonly delayOpenModal: (modal: Modal, timeout: number) => () => void;
  readonly addModalCloseHandler: (
    modalKind: ModalKind,
    callback: () => void,
  ) => void;
  readonly removeModalCloseHandler: (modalKind: ModalKind) => void;
}

export const ModalContext = createContext<ModalContextProps>({
  onOpenModal: () => () => null,
  delayOpenModal: () => () => null,
  closeModal: () => null,
  currentModal: null,
  previousModal: null,
  addModalCloseHandler: () => null,
  removeModalCloseHandler: () => null,
});

const ModalRouter = () => {
  const { currentModal, closeModal } = useModal();

  return (
    <Modal
      isOpen={!!currentModal}
      onClose={closeModal}
      motionPreset="none"
      autoFocus={false}
    >
      <ModalOverlay
        bg={{ base: `transparent`, md: `blackAlpha.600` }}
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
      />
      <ModalContent
        maxW={{ base: `full`, md: `auto` }}
        w={{ base: `full`, md: `auto` }}
        minH={{ base: `full`, md: `auto` }}
        bg={{ base: `grey.25`, md: `transparent` }}
        boxShadow="none"
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
      >
        <AnimatePresence initial={false} mode="wait">
          {!!currentModal ? (
            <AnimatedModalStep key={currentModal.kind}>
              {match(currentModal)
                .with({ kind: ModalKind.EditInstitutionUser }, (modal) => (
                  <EditInstitutionUserModal
                    institutionUser={modal.institutionUser}
                    hasOtherInstitutionAdmins={modal.hasOtherInstitutionAdmins}
                  />
                ))
                .with({ kind: ModalKind.InviteInstitutionUser }, () => (
                  <InviteInstitutionUserModal />
                ))
                .with({ kind: ModalKind.CreateListing }, (modal) => (
                  <CreateListingSequenceModal initialCompany={modal.company} />
                ))
                .with({ kind: ModalKind.BrokerSubmitListing }, (modal) => (
                  <BrokerSubmitListingSequenceModal
                    initialCompany={modal.company}
                  />
                ))
                .with({ kind: ModalKind.ModifyListing }, (modal) => (
                  <ModifyListingModal listing={modal.listing} />
                ))
                .with({ kind: ModalKind.WithdrawListing }, (modal) => (
                  <WithdrawListingModal listing={modal.listing} />
                ))
                .with({ kind: ModalKind.PlaceStandingBid }, (modal) => (
                  <PlaceStandingBidSequenceModal
                    initialCompany={modal.company}
                    initialValues={modal.initialValues}
                  />
                ))
                .with({ kind: ModalKind.WithdrawStandingBid }, (modal) => (
                  <WithdrawStandingBidModal standingBid={modal.standingBid} />
                ))
                .with({ kind: ModalKind.ModifyStandingBid }, (modal) => (
                  <ModifyStandingBidModal standingBid={modal.standingBid} />
                ))
                .with({ kind: ModalKind.AcceptStandingBid }, (modal) => (
                  <AcceptStandingBidSequenceModal
                    standingBid={modal.standingBid}
                  />
                ))
                .with({ kind: ModalKind.PlaceBidSuccess }, (modal) => (
                  <PlaceBidSuccessModal bid={modal.bid} />
                ))
                .with({ kind: ModalKind.PlaceBid }, (modal) => (
                  <PlaceBidSequenceModal
                    company={modal.company}
                    initialListing={modal.initialListing}
                  />
                ))
                .with({ kind: ModalKind.ModifyBid }, (modal) => (
                  <ModifyBidModal bid={modal.bid} />
                ))
                .with({ kind: ModalKind.AcceptBid }, (modal) => (
                  <AcceptBidSequenceModal bid={modal.bid} />
                ))
                .with({ kind: ModalKind.AcceptCounterBid }, (modal) => (
                  <AcceptCounterBidSequenceModal bid={modal.bid} />
                ))
                .with({ kind: ModalKind.CounterBid }, (modal) => (
                  <CounterBidModal bid={modal.bid} />
                ))
                .with({ kind: ModalKind.WithdrawCounterBid }, (modal) => (
                  <WithdrawCounterBidModal bid={modal.bid} />
                ))
                .with({ kind: ModalKind.ModifyCounterBid }, (modal) => (
                  <ModifyCounterBidModal bid={modal.bid} />
                ))
                .with({ kind: ModalKind.InviteAlternateSigner }, (modal) => (
                  <InviteAlternateSignerModal bid={modal.bid} />
                ))
                .with(
                  { kind: ModalKind.RevokeAlternateSignerInvitation },
                  (modal) => (
                    <RevokeAlternateSignerInvitationModal
                      document={modal.document}
                    />
                  ),
                )
                .with({ kind: ModalKind.WithdrawBid }, (modal) => (
                  <WithdrawBidModal bid={modal.bid} />
                ))
                .with(
                  { kind: ModalKind.UnaccreditedSellerWithdrawListing },
                  (modal) => (
                    <UnaccreditedSellerWithdrawListingModal
                      listing={modal.listing}
                    />
                  ),
                )
                .with(
                  { kind: ModalKind.UnaccreditedSellerWithdrawListingV2 },
                  (modal) => (
                    <UnaccreditedSellerWithdrawListingModalV2
                      listing={modal.listing}
                    />
                  ),
                )
                .with(
                  { kind: ModalKind.UnaccreditedSellerCreateListingSuccess },
                  (modal) => (
                    <UnaccreditedSellerCreateListingSuccessModal
                      listing={modal.listing}
                    />
                  ),
                )
                .with(
                  { kind: ModalKind.UnaccreditedSellerAcceptStandingBid },
                  (modal) => (
                    <UnaccreditedSellerAcceptStandingBidSequenceModal
                      standingBid={modal.standingBid}
                    />
                  ),
                )
                .with({ kind: ModalKind.BrokerSubmitBid }, (modal) => (
                  <BrokerSubmitBidSequenceModal {...omit(modal, [`kind`])} />
                ))
                .with({ kind: ModalKind.TransactionModification }, (modal) => (
                  <TransactionModificationModal {...omit(modal, [`kind`])} />
                ))
                .with({ kind: ModalKind.ChangeEmail }, () => (
                  <ChangeEmailModal />
                ))
                .with({ kind: ModalKind.ChangePhone }, () => (
                  <ChangePhoneModal />
                ))
                .with({ kind: ModalKind.ChangePassword }, () => (
                  <ChangePasswordModal />
                ))
                .with({ kind: ModalKind.DisableAppMfa }, (modal) => (
                  <DisableAppMfaModal
                    skipCodeChallenge={modal.skipCodeChallenge}
                  />
                ))
                .with({ kind: ModalKind.DisableAppMfaSuccess }, () => (
                  <DisableAppMfaSuccessModal />
                ))
                .with({ kind: ModalKind.EnrollAppMfa }, (modal) => (
                  <EnrollAppMfaModal
                    qrCode={modal.qrCode}
                    successAction={modal.successAction}
                  />
                ))
                .with({ kind: ModalKind.EnrollAppMfaSuccess }, (modal) => (
                  <EnrollAppMfaSuccessModal recoveryCode={modal.recoveryCode} />
                ))
                .with({ kind: ModalKind.EnrollAppMfaError }, () => (
                  <EnrollAppMfaErrorModal />
                ))
                .with({ kind: ModalKind.DisableSmsMfa }, (modal) => (
                  <DisableSmsMfaModal
                    lastFourDigits={modal.lastFourDigits}
                    otcToken={modal.otcToken}
                    skipCodeChallenge={modal.skipCodeChallenge}
                  />
                ))
                .with({ kind: ModalKind.DisableSmsMfaSuccess }, () => (
                  <DisableSmsMfaSuccessModal />
                ))
                .with({ kind: ModalKind.DisableMfaError }, () => (
                  <DisableMfaErrorModal />
                ))
                .with({ kind: ModalKind.EnrollSmsMfa }, () => (
                  <EnrollSmsMfaModal />
                ))
                .with({ kind: ModalKind.EnrollSmsMfaVerify }, (modal) => (
                  <EnrollSmsMfaVerifyModal
                    otcToken={modal.otcToken}
                    phoneNumber={modal.phoneNumber}
                  />
                ))
                .with({ kind: ModalKind.EnrollSmsMfaSuccess }, (modal) => (
                  <EnrollSmsMfaSuccessModal recoveryCode={modal.recoveryCode} />
                ))
                .with({ kind: ModalKind.DisableAllMfa }, () => (
                  <DisableAllMfaModal />
                ))
                .with({ kind: ModalKind.DisableAllMfaSuccess }, () => (
                  <DisableAllMfaSuccessModal />
                ))
                .with({ kind: ModalKind.Calendly }, (modal) => (
                  <CalendlyModal url={modal.url} />
                ))
                .otherwise((modal) => {
                  throw new Error(`Unknown modal: ${modal.kind}`);
                })}
            </AnimatedModalStep>
          ) : null}
        </AnimatePresence>
      </ModalContent>
    </Modal>
  );
};

type CloseHandler = (() => void) | null;
type CloseHandlers = Partial<Record<ModalKind, CloseHandler>>;

export const ModalProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const [currentModal, setCurrentModal] = useState<Modal | null>(null);
  const [previousModal, setPreviousModal] = useState<Modal | null>(null);
  const { dismissFlows } = useFlowLauncher();

  const closeHandlers = useRef<CloseHandlers>({});

  const addModalCloseHandler = (modalKind: ModalKind, callback: () => void) => {
    closeHandlers.current = {
      ...closeHandlers.current,
      [modalKind]: callback,
    };
  };

  const removeModalCloseHandler = (modalKind: ModalKind) => {
    closeHandlers.current = {
      ...closeHandlers.current,
      [modalKind]: null,
    };
  };

  const closeModal = () => {
    if (currentModal) {
      closeHandlers.current[currentModal.kind]?.();
    }
    setCurrentModal(null);
  };

  const { debounce, clearDebounce } = useDebounce();

  const delayOpenModal = (modal: Modal, delay: number) => () =>
    debounce(() => setCurrentModal(modal), delay);

  const onOpenModal = (modal: Modal, previousModal?: Modal) => () => {
    clearDebounce();
    setCurrentModal(modal);
    dismissFlows();
    if (previousModal) setPreviousModal(previousModal);
  };

  const modalProvider = useMemo(
    () => ({
      onOpenModal,
      closeModal,
      currentModal,
      previousModal,
      delayOpenModal,
      addModalCloseHandler,
      removeModalCloseHandler,
    }),
    [currentModal],
  );

  return (
    <ModalContext.Provider value={modalProvider}>
      <ModalRouter />
      {children}
    </ModalContext.Provider>
  );
};
