export default {
  add_entity: `Add Entity`,
  assign_entity: `Assign Entity`,
  change: `Change`,
  confirm_buyer_information: `Confirm Buyer Information`,
  confirm_multiple_buyer_information_description: `Select or add the individual or entity that will own the shares and appear on the share certificates (or other proof of ownership) at closing. If another individual will purchase the shares, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and details.`,
  confirm_multiple_selling_entity_description: `Select or add the entity that owns the shares and is named on the share certificates (or other proof of ownership). If you need to use multiple entities to complete this transaction, contact <a>execution@hiive.com</a> with your transaction ID {{displayId}} and entity details.`,
  confirm_multiple_seller_information_description: `Select or add the individual or entity that owns the shares and is named on the share certificates (or other proof of ownership). If another individual owns the shares, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and details.`,
  confirm_purchasing_entity: `Confirm Purchasing Entity`,
  confirm_multiple_purchasing_entity_description: `Select or add the entity that will own the shares and appear on the share certificates (or other proof of ownership) at closing. If you are completing this transaction using multiple buying entities, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and entity details. `,
  confirm_single_buyer_information_description: `Please confirm that you are purchasing the shares personally under the name specified below. If an entity or trust will purchase the shares instead, select “Change” in order to specify the purchasing entity. If a different individual will purchase the shares personally, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and details.`,
  confirm_single_purchasing_entity_description: `Please confirm the name of the purchasing entity below, or click “Change” in order to specify a different purchasing entity . If you are completing this transaction using multiple buying entities, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and entity details.  `,
  confirm_selling_entity: `Confirm Selling Entity`,
  confirm_seller_information: `Confirm Seller Information`,
  confirm_single_seller_information_description: `Please confirm that you hold the shares personally and that your name appears as below on the share certificates (or other proof of ownership). If you hold the shares via a holding company or other legal entity, select “Change” below in order to specify the selling entity. If another individual owns the shares personally, please email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and details.`,
  confirm_single_selling_entity_information_description: `Please confirm that the entity below is named on the share certificates (or other proof of ownership) for the shares you are selling. If you need to use multiple entities to complete this transaction, contact <a>execution@hiive.com</a> with your transaction ID {{displayId}} and entity details.`,
  entity_type_buyer_description: `Enter the legal name of the entity that will purchase the shares.`,
  entity_type_seller_description: `Enter the name of the legal entity that holds the shares you are selling.`,
  select_entity_required: `Selecting an entity is required`,
  transacting_entity: `Transacting Entity`,
  transaction_id_required: `Transaction id required`,
};
