import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  useForm,
} from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";

import styles from "./FormPhoneNumberInput.module.css";

interface FormPhoneNumberInputProps<TFieldValues extends FieldValues> {
  readonly autoFocus?: boolean;
  readonly label: string;
  readonly control: Control<TFieldValues>;
  readonly name: Path<TFieldValues>;
  readonly isRequired?: boolean;
}

const FormPhoneNumberInput = <TFieldValues extends FieldValues>({
  autoFocus,
  label,
  name,
  isRequired,
  control,
}: FormPhoneNumberInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });
  const { setValue } = useForm<TFieldValues, Path<TFieldValues>>();

  const { ref, onChange, ...fieldProps } = field;

  const showError = !!error?.message;

  return (
    <FormControl id={name} isInvalid={invalid}>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired ? ` *` : ``}
      </FormLabel>

      <PhoneInput
        {...fieldProps}
        inputProps={{
          autoFocus,
          id: name,
          ref: {
            ...ref,
            // Workaround for react-hook-form focusing this input on form error
            // regardless if it's not the first input with an error... >:(
            // https://github.com/react-hook-form/react-hook-form/issues/6432
            focus: () => null,
          },
        }}
        onChange={(
          _value,
          _c,
          _e,
          formattedValue: PathValue<TFieldValues, Path<TFieldValues>>,
        ) => {
          onChange(formattedValue);
          // Needed for browser autofill to work correctly.
          // https://github.com/orgs/react-hook-form/discussions/1882
          setValue(name, formattedValue);
        }}
        country="us"
        containerClass={styles.container}
        inputClass={`${styles.input} ${showError ? styles.error : ``}`}
        buttonClass={`${styles.inputButton} ${showError ? styles.error : ``}`}
        dropdownClass={styles.dropdown}
      />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormPhoneNumberInput;
