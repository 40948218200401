import { ReactNode, useEffect } from "react";

import { withCurrentActor, WithCurrentActorProps } from "@/components/hoc";
import { useFrontChatRef } from "@/hooks/front";

const FRONT_CHAT_ID = process.env.NEXT_PUBLIC_FRONT_CHAT_ID;

type FrontChatProviderProps = {
  readonly children: ReactNode;
  readonly company?: string;
} & WithCurrentActorProps;

const FrontChatProvider = ({
  children,
  actor,
  company,
}: FrontChatProviderProps) => {
  const frontChatHook = useFrontChatRef();
  const { isInitialized, initialize, frontChat } = frontChatHook;

  useEffect(() => {
    const shouldInitializeChat = FRONT_CHAT_ID && initialize && !isInitialized;

    if (shouldInitializeChat) {
      initialize({
        chatId: FRONT_CHAT_ID,
        userId: actor.id,
        userHash: actor.chatIdHash,
        useDefaultLauncher: true,
        name: actor.name,
        contact: {
          email: actor.email,
          customFields: { "Last Viewed Company": company },
        },
      });
    }
    return () => {
      const shouldShutdownChat = isInitialized && frontChat;

      if (shouldShutdownChat) {
        frontChat(`shutdown`);
      }
    };
  }, [isInitialized, initialize, frontChat]);

  return children;
};

export default withCurrentActor(FrontChatProvider);
