import { MutationTuple } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DefaultValues,
  FieldValues,
  Path,
  useForm,
  Mode,
  UseFormProps,
} from "react-hook-form";
import * as Yup from "yup";

import { InputError } from "@/gql";

const useSubmitMutation = <
  TFieldValues extends FieldValues,
  TMutationData,
  TMutationVariables
>({
  mutation,
  mapVariables,
  onSuccess,
  onError,
}: {
  readonly mutation: MutationTuple<TMutationData, TMutationVariables>;
  readonly mapVariables: (values: TFieldValues) => TMutationVariables;
  readonly onSuccess: (response?: TMutationData | null) => void;
  readonly onError: (errors: readonly InputError[]) => void;
}) => {
  const [runMutation, { loading }] = mutation;

  const onSubmitMutation = async (values: TFieldValues) => {
    const { data } = await runMutation({
      variables: mapVariables(values),
    });

    if (!data) return;

    const mutationErrors = Object.values(data).flatMap(({ errors }) =>
      !!errors ? errors : [],
    );

    if (mutationErrors.length > 0) {
      onError(mutationErrors);
      return;
    }

    onSuccess(data);
  };

  return { onSubmitMutation, isLoading: loading };
};

interface UseHookFormQLProps<
  TFieldValues extends FieldValues,
  TMutationData,
  TMutationVariables
> extends UseFormProps<TFieldValues> {
  readonly validationSchema: Yup.AnyObjectSchema;
  readonly initialValues: DefaultValues<TFieldValues>;
  readonly mutation: MutationTuple<TMutationData, TMutationVariables>;
  readonly mapVariables: (values: TFieldValues) => TMutationVariables;
  readonly onSuccess: (response?: TMutationData | null) => void;
  readonly onError?: () => void;
  readonly mode?: Mode;
  readonly yupContext?: object;
}

const useFormQL = <
  TFieldValues extends FieldValues,
  TMutationData,
  TMutationVariables
>({
  mutation,
  mapVariables,
  onSuccess,
  onError,
  validationSchema,
  initialValues,
  mode = `onSubmit`, // default rhf value
  yupContext,
  ...useFormProps
}: UseHookFormQLProps<TFieldValues, TMutationData, TMutationVariables>) => {
  const {
    handleSubmit: onSubmitForm,
    control,
    setError,
    ...rest
  } = useForm<TFieldValues>({
    resolver: yupResolver(validationSchema),
    context: yupContext,
    defaultValues: initialValues,
    mode,
    ...useFormProps,
  });

  const handleError = (errors: readonly InputError[]) => {
    errors.forEach(({ field, message }) => {
      setError(field as Path<TFieldValues>, { message });
    });

    if (onError) {
      onError();
    }
  };

  const { onSubmitMutation, isLoading } = useSubmitMutation({
    mutation,
    mapVariables,
    onSuccess,
    onError: handleError,
  });

  const handleSubmit = onSubmitForm(onSubmitMutation);

  return { handleSubmit, control, isLoading, ...rest };
};

export default useFormQL;
