import { Minus, Plus } from "@phosphor-icons/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";

import {
  BidPageBuyerActivityBidFragment,
  BidPageSellerActivityBidFragment,
  TransactingEntityItemEntityFragment,
} from "@/gql";
import { Resources } from "@/i18n/locales";

import EntityForm from "./EntityForm";

export enum EntityCardTypes {
  Buyer,
  Seller,
}

export type EntityCardProps = {
  children?: React.ReactNode;
  displayId: string;
  entities?: TransactingEntityItemEntityFragment[];
  isInstitution: boolean;
  transaction:
    | BidPageSellerActivityBidFragment["transaction"]
    | BidPageBuyerActivityBidFragment["transaction"];
  type: EntityCardTypes;
};

const EntityCard = ({
  children,
  displayId,
  entities = [],
  isInstitution,
  transaction,
  type,
}: EntityCardProps) => {
  const { t } = useTranslation(`transactions`);

  const isBuyer = type === EntityCardTypes.Buyer;
  const hasEntity = isBuyer
    ? !!transaction?.buyerEntity?.id
    : !!transaction?.sellerEntity?.id;

  const titleTranslationKey = match<
    {
      isBuyer: boolean;
      isInstitution: boolean;
      hasEntity: boolean;
    },
    keyof Resources["transactions"]
  >({
    isBuyer,
    isInstitution,
    hasEntity,
  })
    .with(
      {
        isBuyer: true,
        isInstitution: false,
        hasEntity: false,
      },
      () => `confirm_buyer_information`,
    )
    .with(
      {
        isBuyer: true,
        isInstitution: true,
        hasEntity: false,
      },
      () => `confirm_purchasing_entity`,
    )
    .with(
      {
        isBuyer: false,
        isInstitution: false,
        hasEntity: false,
      },
      () => `confirm_seller_information`,
    )
    .with(
      {
        isBuyer: false,
        isInstitution: true,
        hasEntity: false,
      },
      () => `confirm_selling_entity`,
    )
    .otherwise(() => `transacting_entity`);

  return (
    <Card variant="darkened-footer" w="full">
      <Accordion allowToggle borderColor="transparent" defaultIndex={0}>
        <AccordionItem w="full">
          {({ isExpanded }) => (
            <>
              <CardHeader
                py={4}
                borderBottom={
                  isExpanded || !hasEntity ? `1px solid gray.200` : `none`
                }
              >
                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text textStyle={{ base: `heading-xl`, md: `heading-2xl` }}>
                    {t(titleTranslationKey)}
                  </Text>
                  {hasEntity && (
                    <AccordionButton as="div" w="initial" p={0} rounded="full">
                      <IconButton
                        background="transparent"
                        h={8}
                        icon={
                          isExpanded ? <Minus size={16} /> : <Plus size={16} />
                        }
                        isRound
                        minW="fit-content"
                        variant="ghost"
                        aria-label={
                          isExpanded
                            ? `hide transacting entity`
                            : `expand transacting entity`
                        }
                        w={8}
                      />
                    </AccordionButton>
                  )}
                </Flex>
              </CardHeader>
              <AccordionPanel p={0}>
                {children && <CardBody>{children}</CardBody>}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      {!hasEntity && (
        <EntityForm
          displayId={displayId}
          entities={entities}
          isInstitution={isInstitution}
          transactionId={transaction!.id}
          type={type}
        />
      )}
    </Card>
  );
};

export default EntityCard;
