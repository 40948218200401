import { WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PIN_LENGTH,
} from "@/components/common";
import { useModal } from "@/hooks";
import { useDisableAppMfa } from "@/hooks/frontegg";

interface ModalBodyWithCodeChallengeProps {
  readonly loading: boolean;
  readonly onOpenDisableAllMfaModal: () => void;
  readonly onPinChange: (value: string) => void;
  readonly onPinComplete: (value: string) => void;
}

const ModalBodyWithCodeChallenge = ({
  loading,
  onOpenDisableAllMfaModal,
  onPinChange,
  onPinComplete,
}: ModalBodyWithCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Trans
        i18nKey="remove_authenticator_app_description_with_code"
        t={t}
        components={{
          a: (
            <Button
              as="span"
              cursor="pointer"
              fontWeight={400}
              textDecoration="underline"
              variant="text-ghost"
              whiteSpace="none"
              onClick={onOpenDisableAllMfaModal}
            />
          ),
        }}
      />
      <CodeInput
        disabled={loading}
        onChange={onPinChange}
        onComplete={onPinComplete}
      />
    </>
  );
};

interface ModalBodyWithoutCodeChallengeProps {
  readonly onOpenDisableAllMfaModal: () => void;
}

const ModalBodyWithoutCodeChallenge = ({
  onOpenDisableAllMfaModal,
}: ModalBodyWithoutCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <Trans
      i18nKey="remove_authenticator_app_description_without_code"
      t={t}
      components={{
        a: (
          <Button
            as="span"
            cursor="pointer"
            fontWeight={400}
            textDecoration="underline"
            variant="text-ghost"
            whiteSpace="none"
            onClick={onOpenDisableAllMfaModal}
          />
        ),
      }}
    />
  );
};

interface ModalFooterWithCodeChallengeProps {
  readonly loading: boolean;
  readonly onPinComplete: () => void;
  readonly pinValue: string;
}

const ModalFooterWithCodeChallenge = ({
  loading,
  onPinComplete,
  pinValue,
}: ModalFooterWithCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <HiiveSubmitButton
      isDisabled={pinValue.length !== PIN_LENGTH || loading}
      sentryLabel="[DisableAppMfa/Submit]"
      submitText={t(`verify_and_remove`)}
      type="submit"
      onClick={onPinComplete}
    />
  );
};

interface ModalFooterWithoutCodeChallengeProps {
  readonly loading: boolean;
  readonly onSubmit: () => void;
}

const ModalFooterWithoutCodeChallenge = ({
  loading,
  onSubmit,
}: ModalFooterWithoutCodeChallengeProps) => {
  const { t } = useTranslation();

  return (
    <HiiveSubmitButton
      isDisabled={loading}
      sentryLabel="[DisableAppMfa/Submit]"
      submitText={t(`remove`)}
      type="submit"
      onClick={onSubmit}
    />
  );
};

interface DisableAppMfaModalProps {
  readonly skipCodeChallenge: boolean;
}

const DisableAppMfaModal = ({
  skipCodeChallenge = false,
}: DisableAppMfaModalProps) => {
  const { t } = useTranslation();
  const { currentModal, onOpenModal, modals } = useModal();
  const [disableMfa, { loading, error }] = useDisableAppMfa();
  const [pinValue, setPinValue] = useState<string>(``);

  const handleOpenDisableAllMfaModal = () => {
    onOpenModal(modals.disableAllMfa(), currentModal)();
  };

  const handlePinChange = (value: string) => setPinValue(value);

  const handlePinComplete = async (value: string) => {
    if (skipCodeChallenge) {
      return;
    }

    const ok = await disableMfa({ token: value });

    if (ok) {
      onOpenModal(modals.disableAppMfaSuccess())();
    }
  };

  const handleSubmit = async () => {
    const ok = await disableMfa();

    if (ok) {
      onOpenModal(modals.disableAppMfaSuccess())();
    }
  };

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`remove_authenticator_app`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          {skipCodeChallenge ? (
            <ModalBodyWithoutCodeChallenge
              onOpenDisableAllMfaModal={handleOpenDisableAllMfaModal}
            />
          ) : (
            <ModalBodyWithCodeChallenge
              loading={loading}
              onOpenDisableAllMfaModal={handleOpenDisableAllMfaModal}
              onPinChange={handlePinChange}
              onPinComplete={handlePinComplete}
            />
          )}
          {error && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          {skipCodeChallenge ? (
            <ModalFooterWithoutCodeChallenge
              loading={loading}
              onSubmit={handleSubmit}
            />
          ) : (
            <ModalFooterWithCodeChallenge
              loading={loading}
              onPinComplete={() => handlePinComplete(pinValue)}
              pinValue={pinValue}
            />
          )}
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default DisableAppMfaModal;
