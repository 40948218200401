import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";

import { InvestingGoalsForm } from "./InvestingGoalsForm";

export const InvestingGoalsPage = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 canGoBack metaTitle="Investor Type">
      <Flex direction="column">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`investor_investing_goals_page_title`)}
          </Text>
          <Text color="grey.900">
            {t(`investor_investing_goals_page_description`)}
          </Text>
        </VStack>
        <InvestingGoalsForm />
      </Flex>
    </OnboardingContainerV2>
  );
};
