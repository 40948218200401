import { FrigadeProvider as BaseFrigadeProvider } from "@frigade/react";
import { createContext, ReactNode, useMemo, useState } from "react";
import { match } from "ts-pattern";

import {
  AlsoPlaceStandingBidFlow,
  UnaccreditedSellerOverviewVideoFlow,
  UnaccreditedSellerTransactionCelebrationFlow,
} from "@/components/frigade";
import { useSession } from "@/hooks/useSession";

import { FlowKind, flowIDs, providerConfig } from "./config";

export const FlowLauncherContext = createContext<{
  readonly activeFlow: FlowKind | null;
  readonly showFlow: (flowKind: FlowKind) => void;
  readonly dismissFlows: () => void;
  readonly getFlowID: (flowKind: FlowKind) => string;
}>({
  activeFlow: null,
  showFlow: () => null,
  dismissFlows: () => null,
  getFlowID: () => ``,
});

const FlowLauncher = ({ children }: { readonly children: ReactNode }) => {
  const [activeFlow, setActiveFlow] = useState<FlowKind | null>(null);

  const showFlow = (flowKind: FlowKind) => {
    setActiveFlow(flowKind);
  };

  const dismissFlows = () => {
    setActiveFlow(null);
  };

  const getFlowID = (flowKind: FlowKind) => flowIDs[flowKind];

  const contextValue = useMemo(
    () => ({
      activeFlow,
      showFlow,
      dismissFlows,
      getFlowID,
    }),
    [activeFlow, showFlow, dismissFlows, getFlowID],
  );

  return (
    <FlowLauncherContext.Provider value={contextValue}>
      {match(activeFlow)
        .with(FlowKind.BuyerPlaceBidSuccessStandingBidCTA, (flowKind) => (
          <AlsoPlaceStandingBidFlow flowKind={flowKind} />
        ))
        .with(FlowKind.BrokerSubmitBidSuccessStandingBidCTA, (flowKind) => (
          <AlsoPlaceStandingBidFlow flowKind={flowKind} />
        ))
        .with(FlowKind.U16RTransactionCelebration, (flowKind) => (
          <UnaccreditedSellerTransactionCelebrationFlow flowKind={flowKind} />
        ))
        .with(FlowKind.U16ROverview, (flowKind) => (
          <UnaccreditedSellerOverviewVideoFlow flowKind={flowKind} />
        ))
        .otherwise(() => null)}
      {children}
    </FlowLauncherContext.Provider>
  );
};

const FrigadeProvider = ({ children }: { readonly children: ReactNode }) => {
  const publicApiKey = process.env.NEXT_PUBLIC_FRIGADE_API_KEY || ``;

  const user = useSession();
  const userId = user?.id;

  return (
    <BaseFrigadeProvider
      key={userId}
      publicApiKey={publicApiKey}
      userId={userId ?? undefined}
      config={providerConfig}
    >
      <FlowLauncher>{children}</FlowLauncher>
    </BaseFrigadeProvider>
  );
};

export default FrigadeProvider;
