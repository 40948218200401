import { Box, Center, Spinner } from "@chakra-ui/react";

import { SomethingWentWrongError } from "@/components/error";
import { AuthenticatedLayout, Layout } from "@/components/layouts";
import { useBasicCurrentContext } from "@/hooks";

const SomethingWentWrong = ({
  isSignedIn,
}: {
  readonly isSignedIn?: boolean;
}) => (
  <Box
    px={{ base: 4, md: 8 }}
    py={{ base: 10, md: 20 }}
    minH="100vh"
    width="100%"
    bg="grey.25"
  >
    <SomethingWentWrongError isSignedIn={isSignedIn} />
  </Box>
);

const SomethingWentWrongPage = () => {
  const { actor, loading } = useBasicCurrentContext();

  if (loading) {
    return (
      <Center m={20}>
        <Spinner />
      </Center>
    );
  }

  if (actor) {
    return (
      <AuthenticatedLayout title="Something went wrong">
        <SomethingWentWrong isSignedIn />
      </AuthenticatedLayout>
    );
  }

  return (
    <Layout title="Something went wrong">
      <SomethingWentWrong />
    </Layout>
  );
};

export default SomethingWentWrongPage;
