import { match, P } from "ts-pattern";

import { useCurrentActor } from "@/hooks";

import { SupportedCountryTraderAwaitingApprovalPage } from "./SupportedCountryTraderAwaitingApprovalPage";
import { USACanadaTraderAwaitingApprovalPage } from "./USACanadaTraderAwaitingApprovalPage";
import { UnsupportedCountryTraderAwaitingApprovalPage } from "./UnsupportedCountryTraderAwaitingApprovalPage";

const TraderAwaitingApprovalPage = () => {
  const actor = useCurrentActor();

  return match(actor.country?.name)
    .with(P.union(`CA`, `US`), () => <USACanadaTraderAwaitingApprovalPage />)
    .with(P.union(`UK`, `IN`, `AU`, `PR`, `AE`, `IL`, `IE`), () => (
      <SupportedCountryTraderAwaitingApprovalPage />
    ))
    .otherwise(() => <UnsupportedCountryTraderAwaitingApprovalPage />);
};

export default TraderAwaitingApprovalPage;
