import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { useRouter } from "next/router";

import { Box, HStack, VStack } from "@chakra-ui/react";

import { InternalLink, Logo } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { EnforceAppMfaConfigurationContext } from "@/components/layouts";
import { CompaniesSearch, NotificationMenu } from "@/components/navigation";
import { UserWithInstitutionFragment } from "@/gql";
import { useHiive50Enabled } from "@/hooks/featureFlags";
import { getIsBroker } from "@/utils";
import config from "@/utils/constants";

import BasicLogout from "./BasicLogout";
import { NavLink } from "./NavLink";
import { ProfileMenu } from "./ProfileMenu";

const HiiveLogo = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) =>
  getIsBroker(actor) ? (
    <InternalLink href="/dashboard" aria-label="Hiive Connect">
      <Logo.ConnectGrey />
    </InternalLink>
  ) : (
    <InternalLink href="/dashboard" aria-label="Hiive">
      <Logo.Grey />
    </InternalLink>
  );

const MainNav = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();
  const isHiive50Enabled = useHiive50Enabled();
  const hideNavComponents = useContext(EnforceAppMfaConfigurationContext);

  const backofficeUrl =
    config.back_office_url[process.env.NEXT_PUBLIC_INFRA_ENV || `local`];

  return hideNavComponents ? (
    <HStack />
  ) : (
    <HStack>
      <NavLink href="/dashboard">{t(`dashboard`)}</NavLink>
      <NavLink href="/companies/browse">{t(`browse_companies`)}</NavLink>
      {isHiive50Enabled && <NavLink href="/hiive50">{t(`hiive_50`)}</NavLink>}
      {actor.isSuperadmin && (
        <NavLink href={backofficeUrl}>{t(`admin`)}</NavLink>
      )}
    </HStack>
  );
};

const SupplementaryNav = ({
  actor,
  maxW,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly maxW: string;
}) => {
  const hideNavComponents = useContext(EnforceAppMfaConfigurationContext);
  return hideNavComponents ? (
    <BasicLogout actor={actor} alignItems="end" />
  ) : (
    <HStack spacing={5}>
      <CompaniesSearch />
      <NotificationMenu key={maxW} />
      <ProfileMenu />
    </HStack>
  );
};

const DesktopNavBar = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { pathname } = useRouter();

    const maxW = match(pathname)
      .with(`/`, () => `max-width-lg`)
      .with(`/dashboard`, () => `max-width-lg`)
      .with(`/companies/browse`, () => `max-width-xl`)
      .with(`/create-listing`, () => `max-width-lg`)
      .with(
        P.when((pathname: string) => pathname.startsWith(`/account`)),
        () => `max-width-lg`,
      )
      .with(
        P.when((pathname: string) => pathname.endsWith(`connect-account`)),
        () => `max-width-lg`,
      )
      .with(P.any, () => `max-width-md`)
      .exhaustive();

    return (
      <Box position="fixed" top={0} left={0} right={0} zIndex="modal">
        <VStack
          h="desktop-navbar-height"
          bg="white"
          px={8}
          borderBottom="0.5px solid"
          borderColor="grey.200"
        >
          <HStack maxW={maxW} justifyContent="space-between" h="full" w="full">
            <HStack spacing={10}>
              <HiiveLogo actor={actor} />
              <MainNav actor={actor} />
            </HStack>
            <SupplementaryNav actor={actor} maxW={maxW} />
          </HStack>
        </VStack>
      </Box>
    );
  },
);

export default DesktopNavBar;
