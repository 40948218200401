import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";
import { InvestorStatus } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { InstitutionAccreditationForm } from "./InstitutionAccreditationForm";
import { InvestorAccreditationForm } from "./InvestorAccreditationForm";

const InvestorAccreditationPageV2 = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();
  const isIndividualUser = actor.investorStatus === InvestorStatus.Individual;

  return (
    <OnboardingContainerV2
      canGoBack={isIndividualUser}
      metaTitle="Investor Accreditation"
    >
      <Flex direction="column">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(
              isIndividualUser
                ? `investor_accreditation_title`
                : `institution_accreditation_title`,
            )}
            *
          </Text>
          {isIndividualUser && (
            <VStack gap={1} alignItems="flex-start">
              <Text>{t`investor_accreditation_description`}</Text>
              <Text>{t`investor_accreditation_description_two`}</Text>
            </VStack>
          )}
        </VStack>

        {isIndividualUser ? (
          <InvestorAccreditationForm />
        ) : (
          <InstitutionAccreditationForm />
        )}
      </Flex>
    </OnboardingContainerV2>
  );
};

export default InvestorAccreditationPageV2;
