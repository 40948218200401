import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PleaseNote,
  WithQuery,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { BidHighFeesWarning } from "@/components/postings";
import {
  AcceptBidModalBidFragment,
  AcceptBidModalCompanyFragment,
  AcceptBidMutation,
  BidState,
  UserWithInstitutionFragment,
  useAcceptBidModalCompanyByIdQuery,
} from "@/gql";
import { useModal } from "@/hooks";
import {
  constants,
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getAreFeesHighForBid,
  transferMethodToString,
  getShortDocumentTitleByTransferMethod,
  makeUrl,
  getIsUnaccreditedSeller,
  appendSellerCompanyIdToUrl,
} from "@/utils";

import { useAcceptBidSequenceModalStepFormContext } from "./AcceptBidSequenceModalStepFormContext";
import { StepKeys } from "./steps";

const ResidualListingWarning = () => (
  <PleaseNote>
    <Text textStyle="deprecated-heading-lg">
      By accepting this bid, the remaining shares in your listing will drop
      below our minimum of {constants.min_listing_size.text} and will no longer
      be listed for sale.
    </Text>
  </PleaseNote>
);

const RejectExcessBidsWarning = () => (
  <PleaseNote>
    <Text textStyle="deprecated-heading-lg">
      After you accept this bid, some other bids will be automatically rejected
      as they cannot be fulfilled with the remaining shares in your listing.
    </Text>
  </PleaseNote>
);

const TOCLink = () => (
  <Link href="/terms-and-conditions" textDecoration="underline" target="_blank">
    Terms & Conditions
  </Link>
);

interface AcceptBidConfirmationModalProps
  extends StepPropsV2<StepKeys, Record<string, never>> {
  readonly bid: AcceptBidModalBidFragment;
  readonly actor: UserWithInstitutionFragment;
}

const AcceptBidConfirmationModalContent = ({
  bid,
  company,
  isSubmitting,
  actor,
  stepRouter: { stepControls },
}: AcceptBidConfirmationModalProps & {
  readonly company: AcceptBidModalCompanyFragment;
}) => {
  const { closeModal } = useModal();

  const router = useRouter();

  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  const documentType = getShortDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );

  const numShares = formatShares(getBidNumSharesActual(bid));

  const pricePerShare = formatPricePerShare(bid.pricePerShare);

  const transferMethod = transferMethodToString(bid.listing.transferMethod);

  const listingNumSharesAfter =
    bid.listing.numSharesAvailable - bid.numSharesActual;

  const willRejectExcessBids = bid.listing.bids
    .filter(
      (otherBid) =>
        otherBid.id !== bid.id && otherBid.state === BidState.Active,
    )
    .some((otherBid) => otherBid.numShares > listingNumSharesAfter);

  const { submitMutation } = useAcceptBidSequenceModalStepFormContext();

  const onClickSubmit = () =>
    submitMutation().then((response: AcceptBidMutation) => {
      const transaction = response.acceptBid.bid?.transaction;

      if (!transaction) return;

      const nextUrl = isUnaccreditedSeller
        ? appendSellerCompanyIdToUrl(makeUrl(transaction), company.id)
        : makeUrl(transaction);

      router.push(nextUrl);
      stepControls.nextStep();
    });

  const isHighFeesWarningVisible = getAreFeesHighForBid({
    pricePerShare: bid.pricePerShare,
    numberOfShares: getBidNumSharesActual(bid),
  });

  return (
    <>
      <HiiveModalHeader>
        Accept Bid{` `}
        {bid.displayId}
      </HiiveModalHeader>
      <ModalBody>
        <VStack align="flex-start" spacing={3}>
          <Text fontWeight="semibold">
            Are you sure you want to accept this bid?
          </Text>
          {isHighFeesWarningVisible && <BidHighFeesWarning bid={bid} />}
          {willRejectExcessBids && <RejectExcessBidsWarning />}
          {bid.listingBelowMinLotOnAcceptance && <ResidualListingWarning />}
          <Text>
            Accepting this bid will constitute an Introduction Event under the
            Hiive Customer{` `}
            <TOCLink />.
          </Text>
          <Text>
            The next step after this will be to execute an{` `}
            {documentType} with the buyer.
          </Text>
          <Text>
            {numShares} shares of{` `}
            {company.name}
            {` `}@ {pricePerShare}/sh{` `}
            {transferMethod}
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[AcceptBidConfirmation/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveSubmitButton
          sentryLabel="[AcceptBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText="Confirm"
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </>
  );
};

const AcceptBidConfirmationModal = ({
  bid,
  ...props
}: AcceptBidConfirmationModalProps) => {
  const query = useAcceptBidModalCompanyByIdQuery({
    variables: { id: bid.company.id },
  });

  return (
    <WithQuery query={query}>
      {({ data: { companyById: company } }) => (
        <AcceptBidConfirmationModalContent
          company={company}
          bid={bid}
          {...props}
        />
      )}
    </WithQuery>
  );
};

export default withCurrentActor(AcceptBidConfirmationModal);
