import { useMemo } from "react";

import { client, createClient } from "@/apollo-client";

import { useServerPreview } from "./useServerPreview";
import { useToken } from "./useToken";

export function createApolloClient(apiUrl: string, token?: string | null) {
  return createClient(apiUrl || ``, token ?? undefined) || client;
}

export function useApolloClient() {
  const apiUrl = useServerPreview() ?? ``;
  const token = useToken();

  const client = useMemo(() => createApolloClient(apiUrl, token), [token]);

  return client;
}
