import { useContext } from "react";

import { LDStatusContext } from "@/components/async-with-ld-provider";

export type FlagValue = boolean | string | string[];

export type FeatureFlags = {
  readonly "hiive.trace_full_graphql": FlagValue;
  readonly "hiive.enable_maintenance": FlagValue;
  readonly "hiive.preqin_integration": FlagValue;
  readonly "hiive.marketplace.react_hook_form_refactor": FlagValue;
  readonly "marketplace.hiive_50": FlagValue;
  readonly "marketplace.multiple_holdings": FlagValue;
  readonly "marketplace.sell_side_broker_portal": FlagValue;
  readonly "issuer.fee_discount.new_proceeds_section": FlagValue;
  readonly "issuer.managed_markets.enable": FlagValue;
  readonly "issuer.managed_markets.fast_follow": FlagValue;
  readonly "issuer.company_approved_buyers.enable": FlagValue;
  readonly "execution.seller_external_account": FlagValue;
  readonly "execution.transaction_execution_automation_system": FlagValue;
  readonly "execution.multi_entity_experience": FlagValue;
  readonly "marketplace.suitability_upfront": FlagValue;
  readonly "marketplace.suppress_notifications": FlagValue;
  readonly "issuer.auth_system.marketplace_configuration": FlagValue;
  readonly "marketplace.trusted-contact-person": FlagValue;
  readonly "marketplace.suitability_v3": FlagValue;
  readonly "marketplace.front_poc": FlagValue;
  readonly "issuer.auth_system.marketplace_session": FlagValue;
};

export enum ServerFeatureFlagKeys {
  ISSUER_AUTH_SYSTEM_MARKETPLACE_LOGIN = `issuer.auth_system.marketplace_login`,
}

export const useFeatureFlags = (): FeatureFlags => {
  const { flags } = useContext(LDStatusContext);

  return (flags as FeatureFlags) || {};
};

export const useMaintenanceMode = () =>
  useFeatureFlags()[`hiive.enable_maintenance`];

export const usePreqinIntegrationEnabled = () =>
  useFeatureFlags()[`hiive.preqin_integration`];

export const useMultipleHoldings = () =>
  useFeatureFlags()[`marketplace.multiple_holdings`];

export const useSellSideBrokerPortalEnabled = () =>
  useFeatureFlags()[`marketplace.sell_side_broker_portal`];

export const useHiive50Enabled = () =>
  useFeatureFlags()[`marketplace.hiive_50`];

export const useFeeDiscountNewProceedsSectionEnabled = () =>
  useFeatureFlags()[`issuer.fee_discount.new_proceeds_section`];

export const useManagedMarketsEnabled = () =>
  useFeatureFlags()[`issuer.managed_markets.enable`];

export const useManagedMarketsFastFollowEnabled = () =>
  useFeatureFlags()[`issuer.managed_markets.fast_follow`];

export const useSellerExternalAccountEnabled = () =>
  useFeatureFlags()[`execution.seller_external_account`];

export const useTEXASEnabled = () =>
  useFeatureFlags()[`execution.transaction_execution_automation_system`];

export const useMultiEntityExperienceEnabled = () =>
  useFeatureFlags()[`execution.multi_entity_experience`];

export const useSuitabilityUpfront = () =>
  useFeatureFlags()[`marketplace.suitability_upfront`];

export const useSuppressNotifications = () =>
  useFeatureFlags()[`marketplace.suppress_notifications`];

export const useTrustedContactPerson = () =>
  useFeatureFlags()[`marketplace.trusted-contact-person`];

export const useSuitabilityV3 = () =>
  useFeatureFlags()[`marketplace.suitability_v3`];

export const useFrontPOC = () => useFeatureFlags()[`marketplace.front_poc`];

export const useCompanyApprovedBuyersEnabled = () =>
  useFeatureFlags()[`issuer.company_approved_buyers.enable`];

export const useMarketplaceConfiguration = () =>
  useFeatureFlags()[`issuer.auth_system.marketplace_configuration`];

export const useMarketplaceSession = () =>
  useFeatureFlags()[`issuer.auth_system.marketplace_session`];
