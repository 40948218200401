import { CurrentActorContext } from "@/components/providers";
import { SuitabilityQuestionGroupsQuery } from "@/gql";
import { getIsInstitutionUser } from "@/utils";

export const getSuitabilityAnswersForActor = (actor: CurrentActorContext) => {
  const isInstitutionUser = getIsInstitutionUser(actor);
  return (
    (isInstitutionUser
      ? actor.institution?.suitabilityAnswers
      : actor.suitabilityAnswers) || []
  );
};

export const getCurrentAnsweredVersion = (actor: CurrentActorContext) => {
  const suitabilityAnswers = getSuitabilityAnswersForActor(actor);
  return (
    (suitabilityAnswers &&
      suitabilityAnswers[0]?.suitabilityOption?.suitabilityQuestion
        ?.suitabilityQuestionGroup?.version) ||
    0
  );
};

export const areSuitabilityAnswersCurrent = (
  actor: CurrentActorContext,
  questionSet: SuitabilityQuestionGroupsQuery["currentSuitabilityQuestionGroup"],
) => {
  const currentVersion = questionSet?.version;

  // Optimistically return true while we wait for requests to finish
  if (!questionSet) return true;

  return currentVersion === getCurrentAnsweredVersion(actor);
};

export const getSuitabilityLastUpdatedAtForActor = (
  actor: CurrentActorContext,
) => {
  const isInstitutionUser = getIsInstitutionUser(actor);
  const { suitabilityLastUpdatedAt } = isInstitutionUser
    ? // This check isn't necessary but ts throws a type error if it isnt here. Case is handled above
      actor.institution ?? actor
    : actor;
  return suitabilityLastUpdatedAt;
};
